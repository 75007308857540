import React from 'react';

const mainColor = '#1f1f1f'
const secondaryColor = '#ffffff'
const strokeWidth = 40
const strokeColor = 'none'

export const YouTubeTwoColorIcon = ({ size, firstColor, secondColor }) => {

    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 995.42 995.42"
            width={size}
            height={size}
        >
            <g id="YouTube">
                <rect x="224.87" y="305.96" width="562.13" height="384.08" fill='#ffffff' />
                <path
                    d="M894.38,10H101.63C51.28,10,10,51.28,10,101.34V894.09c0,50.35,41.28,91.33,91.63,91.33H894.38c50.35,0,91.63-41.27,91.63-91.33V101.34C986,51,944.73,10,894.38,10ZM765.28,388.81s-5.27-37.76-21.66-54.16c-20.79-21.66-44.21-21.95-54.75-23.12C612.47,306,498,306,498,306h-.29S383,306,306.84,311.53c-10.54,1.17-34,1.46-54.74,23.12-16.39,16.4-21.66,54.16-21.66,54.16s-5.57,44.2-5.57,88.41V518.5c0,44.2,5.57,88.4,5.57,88.4s5.27,37.77,21.66,54.16c20.78,21.67,48,21.08,60.3,23.42C356,688.58,498,690,498,690s114.76-.29,191.17-5.56c10.53-1.17,34-1.46,54.74-23.13C760.3,645,765.57,607.2,765.57,607.2s5.56-44.21,5.56-88.41V477.51c0-44.2-5.56-88.41-5.56-88.41Zm-324.07,180V415.45l147.54,77Z"
                    fill={ firstColor ? firstColor : mainColor }
                    fillRule="evenodd"
                />
                <rect
                    x="10"
                    y="10"
                    width="975.42"
                    height="975.42"
                    rx="87.81"
                    fill="none"
                    stroke={strokeColor}
                    strokeMiterlimit="10"
                    strokeWidth="40"
                />
            </g>
        </svg>
    );
};

export const FacebookTwoColorIcon = ({ size, firstColor, secondColor }) => {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1015.42209 1015.42209"
            width={size}
            height={size}
        >
            <defs>
                {/* Unique id for encapsulated styles */}
                <style>
                    {`
            .facebook-icon .cls-1 { fill: ${secondColor ? secondColor : secondaryColor }; }
            .facebook-icon .cls-2 { fill: ${ firstColor ? firstColor : mainColor }; fill-rule: evenodd; }
            .facebook-icon .cls-3 { fill: none; stroke: ${strokeColor}; stroke-miterlimit: 10; stroke-width: ${strokeWidth}; }
          `}
                </style>
            </defs>
            <g id="Facebook" className="facebook-icon"> {/* Add the unique class here */}
                <rect className="cls-1" x="313.00379" y="127.874" width="400" height="742.66667" />
                <path
                    className="cls-2"
                    d="M904.49975,20.00076H111.52609c-50.32342,0-91.517,41.192-91.517,91.517V904.49134c0,50.32343,41.19354,91.51547,91.517,91.51547H904.49975c50.32342,0,91.517-41.192,91.517-91.51547V111.51619C996.01671,61.19277,954.82317,20.00076,904.49975,20.00076ZM574.83555,285.0405H686.38123V151.26865H574.83555c-85.89752,0-155.97975,70.06393-155.97975,156.06971v66.85849H329.66288V508.00377h89.17463V864.7389H552.62768V508.00377H664.06354l22.31615-133.80692H552.62615V307.33836a22.58468,22.58468,0,0,1,22.2094-22.31615v.01829Z"
                />
                <rect
                    id="Square-Stroke"
                    className="cls-3"
                    x="20"
                    y="20"
                    width="975.42209"
                    height="975.42209"
                    rx="87.81282"
                />
            </g>
        </svg>
    );
};



export const InstagramTwoColorIcon = ({ size, firstColor, secondColor }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 1015.42 1015.42"
            width={size}
            height={size}
        >
            <defs>
                {/* Unique id for encapsulated styles */}
                <style>
                    {`
            .instagram-icon .cls-1 { fill: ${secondColor ? secondColor : secondaryColor }; }
            .instagram-icon .cls-2 { fill: ${ firstColor ? firstColor : mainColor }; fill-rule: evenodd; }
            .instagram-icon .cls-3 { fill: none; stroke: ${strokeColor}; stroke-miterlimit: 10; stroke-width: ${strokeWidth}; }
          `}
                </style>
            </defs>
            <g id="Instagram" className="instagram-icon">
                <rect className="cls-1" x="153" y="127.87" width="704" height="742.67" />
                <path
                    className="cls-2"
                    d="M902.67,20h-793c-50.32,0-91.5,41.17-91.5,91.5v793c0,50.32,41.18,91.5,91.5,91.5h793c50.33,0,91.5-41.18,91.5-91.5v-793C994.17,61.17,953,20,902.67,20ZM361.37,168.46H651c108.75,0,197.68,87.82,197.68,195.12V652.43c0,107.31-88.93,195.12-197.68,195.12H361.37c-108.74,0-197.69-87.81-197.69-195.12V363.58C163.68,256.28,252.63,168.46,361.37,168.46Zm143,156.2c105.68,0,191.45,85.75,191.45,191.45S610,707.54,504.33,707.54,312.88,621.8,312.88,516.11,398.62,324.66,504.33,324.66Zm0,64.66a126.79,126.79,0,1,1-126.8,126.79,126.83,126.83,0,0,1,126.8-126.79Zm185.22-91.19a31.08,31.08,0,1,1-31.09,31.07A31.06,31.06,0,0,1,689.55,298.13ZM385,223.93H627.34c91,0,165.38,74,165.38,164.51V632c0,90.48-74.4,164.51-165.38,164.51H385c-91,0-165.39-74-165.39-164.51V388.44c0-90.48,74.43-164.51,165.39-164.51Z"
                />
                <rect id="Square-Stroke" className="cls-3" x="20" y="20" width="975.42" height="975.42" rx="87.81" />
            </g>
        </svg>
    );
};


export const TwitterTwoColorIcon = ({ size, firstColor, secondColor }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 1015.42209 1015.42209"
            width={size}
            height={size}
        >
            <defs>
                {/* Unique id for encapsulated styles */}
                <style>
                    {`
            .twitter-icon .cls-1 { fill: ${secondColor ? secondColor : secondaryColor }; }
            .twitter-icon .cls-2 { fill: ${ firstColor ? firstColor : mainColor }; fill-rule: evenodd; }
            .twitter-icon .cls-3 { fill: none; stroke: ${strokeColor}; stroke-miterlimit: 10; stroke-width: ${strokeWidth}; }
          `}
                </style>
            </defs>
            <g id="Twitter" className="twitter-icon">
                <rect className="cls-1" x="153.00379" y="127.874" width="704" height="742.66667" />
                <path
                    className="cls-2"
                    d="M913.5396,8.58836H102.468c-51.47195,0-93.58537,42.11341-93.58537,93.58536V913.24687c0,51.472,42.11342,93.58536,93.58537,93.58536H913.5396c51.472,0,93.58536-42.11341,93.58536-93.58536V102.17372C1007.125,50.70177,965.01155,8.58836,913.5396,8.58836Zm-93.49492,305.6108A251.65118,251.65118,0,0,1,746.61291,334.242a128.60627,128.60627,0,0,0,56.29939-70.83319,257.67228,257.67228,0,0,1-81.243,31.13272A128.22555,128.22555,0,0,0,503.47189,411.30492,363.89007,363.89007,0,0,1,239.64228,277.50124a128.00061,128.00061,0,0,0,39.55384,170.79638,128.26743,128.26743,0,0,1-58.03227-15.91729v1.62216A128.08277,128.08277,0,0,0,323.88938,559.53168a128.2627,128.2627,0,0,1-57.86538,2.193,128.09767,128.09767,0,0,0,119.54282,88.9061,256.79268,256.79268,0,0,1-159.02336,54.84569,262.33142,262.33142,0,0,1-30.58059-1.78747,361.91129,361.91129,0,0,0,196.32961,57.477c235.42178,0,364.21394-195.05686,364.21394-364.19369,0-5.6198-.09358-11.10857-.36809-16.61765a255.9821,255.9821,0,0,0,63.833-66.09933l.07483-.05614Z"
                />
                <rect id="Square-Stroke" className="cls-3" x="20" y="20" width="975.42209" height="975.42209" rx="87.81282" />
            </g>
        </svg>
    );
};

export const SoundCloudTwoColorIcon = ({ size, firstColor, secondColor }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 1015.42209 1015.42209"
            width={size}
            height={size}
        >
            <defs>
                {/* Unique id for encapsulated styles */}
                <style>
                    {`
            .soundcloud-icon .cls-1 { fill: ${ firstColor ? firstColor : mainColor }; }
            .soundcloud-icon .cls-2 { fill: ${secondColor ? secondColor : secondaryColor }; }
            .soundcloud-icon .cls-3 { fill: none; stroke: ${strokeColor}; stroke-miterlimit: 10; stroke-width: ${strokeWidth}; }
          `}
                </style>
            </defs>
            <g id="SoundCloud" className="soundcloud-icon">
                <rect className="cls-1" x="9.00379" y="1.20733" width="1004" height="1004" rx="158.66667" />
                <path
                    className="cls-2"
                    d="M146.35771,645.602a3.47809,3.47809,0,0,0,3.28533-3.26933L158.61638,571.21l-8.94667-72.74133a3.33648,3.33648,0,0,0-6.672,0L135.15505,571.21l7.84266,71.09868A3.52827,3.52827,0,0,0,146.35771,645.602Z"
                />
                <path
                    className="cls-2"
                    d="M116.771,618.55133a3.3045,3.3045,0,0,0,3.17334-3.14665l6.95466-44.19734-6.95466-45.024a3.20975,3.20975,0,0,0-6.41867,0l-5.81334,45,5.81334,44.184A3.34108,3.34108,0,0,0,116.771,618.55133Z"
                />
                <path
                    className="cls-2"
                    d="M181.74437,484.93a3.992,3.992,0,1,0-7.984,0l-7.496,86.28,7.496,83.13066a3.99208,3.99208,0,0,0,7.984,0l8.48-83.13066Z"
                />
                <path
                    className="cls-2"
                    d="M209.45105,661.65534a4.88381,4.88381,0,0,0,4.688-4.66936l8.01334-85.74931-8.01334-88.688a4.8648,4.8648,0,0,0-4.688-4.664,4.79225,4.79225,0,0,0-4.68267,4.67733l-7.08266,88.67464,7.08266,85.74935A4.804,4.804,0,0,0,209.45105,661.65534Z"
                />
                <path
                    className="cls-2"
                    d="M241.38438,663.04734a5.54444,5.54444,0,0,0,5.4-5.36267l-.02666.02133,7.54666-86.46933-7.54667-82.28532a5.52714,5.52714,0,0,0-5.376-5.34668,5.46781,5.46781,0,0,0-5.36534,5.37069l-6.672,82.264,6.672,86.448A5.44588,5.44588,0,0,0,241.38438,663.04734Z"
                />
                <path
                    className="cls-2"
                    d="M286.73106,571.258l-7.08-133.792a6.06674,6.06674,0,1,0-12.13333,0l-6.26666,133.792,6.26666,86.448a6.06667,6.06667,0,1,0,12.13333,0v.024Z"
                />
                <path
                    className="cls-2"
                    d="M306.075,663.922a6.88242,6.88242,0,0,0,6.70134-6.744v.056l6.61867-85.95467-6.61867-164.416a6.744,6.744,0,1,0-13.488,0l-5.82666,164.416,5.85333,85.92533A6.87375,6.87375,0,0,0,306.075,663.922Z"
                />
                <path
                    className="cls-2"
                    d="M338.76571,385.706a7.58468,7.58468,0,0,0-7.432,7.464l-5.42133,178.136,5.42133,85.024a7.42667,7.42667,0,1,0,14.85333-.01331v.04269l6.152-85.05335L346.187,393.17A7.55014,7.55014,0,0,0,338.76571,385.706Z"
                />
                <path
                    className="cls-2"
                    d="M371.73906,663.922a8.2122,8.2122,0,0,0,8.09867-8.14933v.05066l5.688-84.51466-5.688-184.12a8.2247,8.2247,0,0,0-8.09867-8.14933,8.1294,8.1294,0,0,0-8.08267,8.14933l-5.10933,184.12,5.10933,84.51466A8.10807,8.10807,0,0,0,371.73906,663.922Z"
                />
                <path
                    className="cls-2"
                    d="M404.98438,663.83665a8.91988,8.91988,0,0,0,8.776-8.848v.07735l5.23466-83.744L413.76038,391.8847a8.79208,8.79208,0,1,0-17.584,0l-4.6,179.43734,4.62933,83.704A8.87039,8.87039,0,0,0,404.98438,663.83665Z"
                />
                <path
                    className="cls-2"
                    d="M452.6697,571.322,447.931,398.426a9.47491,9.47491,0,1,0-18.94934.03467L424.731,571.322l4.25067,83.19466a9.47486,9.47486,0,1,0,18.94934-.07735v.07735Z"
                />
                <path
                    className="cls-2"
                    d="M472.19774,664.154a10.30386,10.30386,0,0,0,10.14664-10.208v.04532l4.30136-82.624L482.34438,365.626a10.15235,10.15235,0,1,0-20.304.02934l-3.824,205.62936,3.824,82.70935A10.323,10.323,0,0,0,472.19774,664.154Z"
                />
                <path
                    className="cls-2"
                    d="M505.82172,336.05a11.02471,11.02471,0,0,0-10.864,10.94133L490.51235,571.394l4.472,81.40267a10.81508,10.81508,0,1,0,21.62931-.09867v.09867L521.467,571.394l-4.85336-224.40267A10.96124,10.96124,0,0,0,505.82172,336.05Z"
                />
                <path
                    className="cls-2"
                    d="M536.56574,664.23135c.24.02132,270.99464.14933,272.73333.14933,54.36,0,98.41064-44.592,98.41064-99.61866,0-54.99735-44.0533-99.59733-98.41064-99.59733a96.8393,96.8393,0,0,0-38.06666,7.752c-7.792-89.78935-82.17334-160.20532-172.912-160.20532a173.83687,173.83687,0,0,0-62.91467,11.91733c-7.43466,2.90666-9.424,5.904-9.48267,11.712v316.104A12.00347,12.00347,0,0,0,536.56574,664.23135Z"
                />
                <rect id="Square-Stroke" className="cls-3" x="20" y="20" width="975.42209" height="975.42209" rx="87.81282" />
            </g>
        </svg>
    );
};

export const TikTokTwoColorIcon = ({ size, firstColor, secondColor }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 1015.42209 1015.42209"
            width={size}
            height={size}
        >
            <defs>
                {/* Unique id for encapsulated styles */}
                <style>
                    {`
            .tiktok-icon .cls-1 { fill: ${ firstColor ? firstColor : mainColor }; }
            .tiktok-icon .cls-2 { fill: ${secondColor ? secondColor : secondaryColor }; }
            .tiktok-icon .cls-3 { fill: none; stroke: ${strokeColor}; stroke-miterlimit: 10; stroke-width: ${strokeWidth}; }
            .tiktok-icon .cls-4 { fill: ${ firstColor ? firstColor : mainColor }; fill-rule: evenodd; }
          `}
                </style>
            </defs>
            <g id="TikTok" className="tiktok-icon">
                <rect className="cls-2" x="153.00379" y="127.874" width="704" height="742.66667" />
                <path
                    className="cls-4"
                    d="M908.8955,6.24752H101.11208c-51.30678,0-93.36641,42.05962-93.36641,93.0681V907.09905c0,51.30677,42.05963,93.0681,93.36641,93.0681H908.8955c51.30678,0,93.3664-42.05962,93.3664-93.0681V99.31562C1002.2619,48.00884,960.20228,6.24752,908.8955,6.24752ZM626.70823,192.08543c10.44034,88.29538,59.659,141.09363,145.26977,146.76124V438.179c-49.8153,4.77272-93.3664-11.33522-144.07659-42.05963V581.95727c0,236.2498-257.42876,310.227-360.9372,140.79534C200.44438,613.87486,241.01253,422.66763,454.5919,414.912V519.91187a362.29478,362.29478,0,0,0-49.517,11.9318c-47.42894,16.10794-74.2755,46.23576-66.81812,99.034,14.31817,101.71867,201.051,131.54819,185.24132-67.11642V191.78714H626.40994Z"
                />
                <rect id="Square-Stroke-2" data-name="Square-Stroke" className="cls-3" x="20" y="20" width="975.42209" height="975.42209" rx="87.81282" />
            </g>
        </svg>
    );
};

export const WebsiteTwoColorIcon = ({ size, firstColor, secondColor }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 1015.42209 1015.42209"
            width={size}
            height={size}
        >
            <defs>
                {/* Unique id for encapsulated styles */}
                <style>
                    {`
            .website-icon .cls-1 { fill: ${ firstColor ? firstColor : mainColor }; }
            .website-icon .cls-2 { fill: ${ firstColor ? firstColor : mainColor }; }
            .website-icon .cls-3 { fill: ${secondColor ? secondColor : secondaryColor }; }
            .website-icon .cls-4, .website-icon .cls-5 { fill: none; }
            .website-icon .cls-4 { stroke: ${ firstColor ? firstColor : mainColor }; stroke-width: 20px; }
            .website-icon .cls-5 { stroke: ${secondColor ? secondColor : secondaryColor }; stroke-miterlimit: 10; stroke-width: ${strokeWidth}; }
          `}
                </style>
            </defs>
            <g id="Globe" className="website-icon">
                <rect className="cls-1" x="9.00379" y="1.20733" width="1004" height="1004" rx="158.66667" />
                <rect className="cls-2" x="37.79403" y="41.58366" width="936.39133" height="936.39133" />
                <circle className="cls-3" cx="505.98969" cy="509.77932" r="413.88497" />
                <path className="cls-4" d="M504.11691,144.58671c-201.68766,1.03431-364.34935,165.37294-363.315,367.0606s165.373,364.34932,367.0606,363.315,364.34933-165.37291,363.315-367.0606c-1.02681-200.22649-163.08858-362.28822-363.315-363.315Zm1.87278,0V874.97194M871.18231,509.77932H140.79707m82.40244-224.73391a486.92349,486.92349,0,0,0,565.58036,0m0,449.46783a486.92359,486.92359,0,0,0-565.58036,0M477.898,153.95062c-197.55308,166.83828-222.45234,462.23589-55.614,659.78891a468.1945,468.1945,0,0,0,55.614,55.61406m56.18348,0C731.63451,702.51528,756.53375,407.11774,589.6955,209.56466a468.19683,468.19683,0,0,0-55.61407-55.614" />
                <rect id="Square-Stroke" className="cls-5" x="20" y="20" width="975.42209" height="975.42209" rx="87.81282" />
            </g>
        </svg>
    );
};