import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import ArchiveIcon from '@mui/icons-material/Archive';
import Flag from 'react-world-flags';
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import MenuNavBar from "./MenuNavBar";
import {FormControl, Grid, Select, Typography} from "@mui/material";
import { SelvansLogo } from "../../media/logo/SelvansLogo";
import { ThemeContext } from "../../contexts/ThemeContext";
import { useContext, useState } from "react";
import { LanguageContext } from "../../contexts/LocalizationContext/languages/LanguageContext";
import { styled } from "@mui/material/styles";
import {donatePaypalMattSelvans, PagesList, paypalDonateLink, youTubeChannel} from "../../config";
import { LayoutContext } from "../../contexts/LayoutContext/LayoutContext";
import { PaypalDonateButton } from "../../media/PaypalDonateButton";
import {CampingInTheSkiesLogo} from "../../media/logo/CampingInTheSkiesLogo";
import {Link as RouterLink} from "react-router-dom";

const Link = styled(RouterLink)(() => ({
    textDecoration: "none",
    color: '#000000',
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}));

export const MainMenuOptions = ({ isColumnMenu, handleClose }) => {

    const { theme } = useContext(ThemeContext)

    const menuOptions = [
        { route: PagesList.homepage.route, name: 'Home', icon: <ArchiveIcon /> },
        { route: PagesList.aboutUs.route, name: 'About', icon: <ArchiveIcon /> },
        { route: PagesList.discography.route, name: 'Discography', icon: <ArchiveIcon /> },
        // { route: PagesList.partners.route, name: 'Partners', icon: <ArchiveIcon /> },
        // { route: null, url: youTubeChannel, name: 'Media', icon: <ArchiveIcon /> },
        // { route: PagesList.homepage.route, name: 'Links', icon: <ArchiveIcon /> },
        { route: PagesList.newsletter.route, name: 'Newsletter', icon: <ArchiveIcon /> },
        { route: PagesList.photos.route, name: 'Photos', icon: <ArchiveIcon /> },
        { route: PagesList.videos.route, name: 'Videos', icon: <ArchiveIcon /> },
        { route: null, url: paypalDonateLink, name: 'Donate', icon: <ArchiveIcon /> },
    ]

    return (
        <>
            {isColumnMenu === true ? (
                menuOptions.map((menuOption, i) => (
                    <MenuItem onClick={handleClose} disableRipple key={i}>
                        {menuOption.route === null ? (
                            <a id={i} href={menuOption.url} target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: theme.palette.text.primary, alignItems: "center",}}>
                                {menuOption.icon}
                                {menuOption.name}
                            </a>
                        ) : (
                            <Link id={i} to={menuOption.route}>
                                {menuOption.icon}
                                {menuOption.name}
                            </Link>
                        )}
                    </MenuItem>
                ))
            ) : (
                menuOptions.map((menuOption, i) => (
                    menuOption.route === null ? (
                        <a id={i} href={menuOption.url} target="_blank" rel="noreferrer" style={{textDecoration: 'none', color: theme.palette.text.primary}}>
                            {menuOption.name}
                        </a>
                    ) : (
                        <Link id={i} to={menuOption.route} key={i}>
                            <Typography>{menuOption.name}</Typography>
                        </Link>
                    )
                ))
            )}
        </>
    )
}



const StyledAppBar = styled(AppBar)(({ theme }) => ({
    width: '100vw',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up("sm")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    // Media query for mobile devices
    [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    // Fade-in and fade-out transitions using visibility and opacity
    opacity: 1,
    visibility: 'visible', // Initially set to visible
    transition: "opacity 0.3s, visibility 0.3s",
    '&.hidden': {
        opacity: 0,
        visibility: 'hidden', // Hide the content, but keep the space occupied
        pointerEvents: 'none', // Disable pointer events when hidden
    },
}));

const StyledFormControl = styled(FormControl)(() => ({
    '&.MuiFormControl-root': {
        padding: 0,
    }
}));

const StyledSelect = styled(Select)(() => ({
    '& .MuiSelect-select': {
        // padding: 0,
        paddingRight: 0,
    }
}));

const StyledIconButton = styled(IconButton)(() => ({
    '&.MuiIconButton-root': {
        padding: 0,
    },
}));

export default function NavBar() {
    const { isNavBarVisible, isMobile, isTablet } = useContext(LayoutContext);
    const { language, setLanguage } = useContext(LanguageContext);
    const { theme } = useContext(ThemeContext);

    const [languageSelected, setLanguageSelected] = useState(language);

    const handleChange = (event) => {
        setLanguageSelected(event.target.value);
    };

    const handleClick = (lang) => {
        setLanguage(lang);
    };

    return (
        <React.Fragment>
            <StyledAppBar
                component="nav"
                position="fixed"
                className={isNavBarVisible ? '' : 'hidden'}
            >
                <Grid container direction="row" justifyContent="center" alignItems="center" sx={{height: 70, width: '100vw'}}>
                    <Grid item lg={5} sm={9} xs={9} sx={{ display: "flex",  justifyContent: 'center'}}>
                        {/*<CampingInTheSkiesLogo size={60}/>*/}
                        <Link to={PagesList.homepage.route}>
                            <Typography variant={ isMobile ? 'h6' : 'h4'}>Camping In The Skies</Typography>
                        </Link>
                    </Grid>
                    <Grid item lg={7} sm={3} xs={3}  sx={{ display: "flex", gap: isMobile ? "0" : "25px", justifyContent: 'center'}}>
                        {/*<a href={donatePaypalMattSelvans} target="_blank"  style={{ paddingRight: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}} rel="noreferrer">*/}
                        {/*    <PaypalDonateButton height={28} />*/}
                        {/*</a>*/}
                        {/*<Box>*/}
                        {/*    <StyledFormControl>*/}
                        {/*        <StyledSelect*/}
                        {/*            labelId="demo-simple-select-label"*/}
                        {/*            id="demo-simple-select"*/}
                        {/*            value={languageSelected}*/}
                        {/*            label="Language"*/}
                        {/*            onChange={handleChange}*/}
                        {/*            sx={{*/}
                        {/*                '& .MuiOutlinedInput-notchedOutline': {*/}
                        {/*                    border: 'none',*/}
                        {/*                },*/}
                        {/*                '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {*/}
                        {/*                    padding: 0,*/}
                        {/*                },*/}
                        {/*            }}*/}
                        {/*            IconComponent={null}*/}
                        {/*            MenuProps={{*/}
                        {/*                getContentAnchorEl: null,*/}
                        {/*            }}*/}

                        {/*        >*/}
                        {/*            <MenuItem value="en" sx={{ justifyContent: 'center' }}>*/}
                        {/*                <StyledIconButton onClick={() => handleClick('en')}>*/}
                        {/*                    <Flag code="826" height="23" />*/}
                        {/*                </StyledIconButton>*/}
                        {/*            </MenuItem>*/}
                        {/*            <MenuItem value="es" sx={{ justifyContent: 'center' }}>*/}
                        {/*                <StyledIconButton onClick={() => handleClick('es')}>*/}
                        {/*                    <Flag code="Es" height="24" />*/}
                        {/*                </StyledIconButton>*/}
                        {/*            </MenuItem>*/}
                        {/*            <MenuItem value="it" sx={{ justifyContent: 'center' }}>*/}
                        {/*                <StyledIconButton onClick={() => handleClick('it')}>*/}
                        {/*                    <Flag code="It" height="24" />*/}
                        {/*                </StyledIconButton>*/}
                        {/*            </MenuItem>*/}
                        {/*        </StyledSelect>*/}
                        {/*    </StyledFormControl>*/}
                        {/*</Box>*/}

                        { ( isMobile || isTablet ) ? <MenuNavBar /> : <MainMenuOptions />}
                    </Grid>
                </Grid>
            </StyledAppBar>
        </React.Fragment>
    );
}
