import React from 'react';

const mainColor = '#1f1f1f'

export const SpotifyIcon = ({ size }) => (
    <svg
        id="svg"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={size}
        height={size}
        viewBox="0 0 400 400"
        style={{ borderRadius: 5 }}
    >
        <g id="svgg">
            <path
                id="path0"
                d="M187.400 42.839 C 179.958 43.618,176.659 44.092,168.200 45.603 C 165.148 46.148,155.921 48.579,151.600 49.978 C 148.772 50.892,144.109 52.580,142.200 53.380 C 141.540 53.657,139.740 54.398,138.200 55.029 C 107.772 67.481,79.944 92.384,62.688 122.600 C 60.750 125.994,55.769 136.182,54.572 139.200 C 54.180 140.190,53.547 141.720,53.167 142.600 C 50.768 148.149,46.514 162.993,45.201 170.400 C 44.947 171.830,44.584 173.810,44.395 174.800 C 44.004 176.841,43.391 182.140,42.801 188.569 C 42.287 194.174,42.285 206.906,42.798 211.916 C 43.617 219.918,44.084 223.521,44.856 227.800 C 45.591 231.870,47.592 240.593,48.434 243.400 C 49.589 247.251,52.920 257.062,53.187 257.400 C 53.360 257.620,53.636 258.250,53.799 258.800 C 54.082 259.755,54.373 260.448,56.399 265.000 C 74.511 305.679,115.139 341.064,155.400 351.226 C 156.060 351.392,158.760 352.097,161.400 352.791 C 185.072 359.017,215.591 359.086,238.300 352.966 C 239.455 352.655,241.435 352.124,242.700 351.787 C 277.024 342.642,310.477 318.462,330.672 288.200 C 335.871 280.409,344.800 264.039,344.800 262.298 C 344.800 262.002,344.953 261.589,345.140 261.380 C 345.902 260.530,350.419 247.835,351.787 242.700 C 352.124 241.435,352.658 239.449,352.973 238.286 C 359.063 215.798,359.008 185.468,352.835 161.600 C 352.180 159.070,351.530 156.550,351.389 156.000 C 343.243 124.127,318.044 89.397,288.800 69.739 C 285.387 67.444,279.225 63.600,278.960 63.600 C 278.863 63.600,278.095 63.165,277.253 62.633 C 275.593 61.586,268.111 57.775,265.200 56.495 C 264.210 56.059,262.230 55.207,260.800 54.600 C 259.370 53.994,257.611 53.240,256.892 52.926 C 255.489 52.313,246.586 49.314,244.600 48.786 C 223.634 43.206,203.704 41.134,187.400 42.839"
                stroke={"none"}
                fill={"#fff"}
                // fill={"#1dda63"}
                fillRule="evenodd"
            />
            <path
                id="path1"
                d="M0.000 200.000 L 0.000 400.000 200.000 400.000 L 400.000 400.000 400.000 200.000 L 400.000 0.000 200.000 0.000 L 0.000 0.000 0.000 200.000 M213.600 42.992 C 226.269 44.283,231.003 45.167,244.600 48.786 C 246.586 49.314,255.489 52.313,256.892 52.926 C 257.611 53.240,259.370 53.994,260.800 54.600 C 262.230 55.207,264.210 56.059,265.200 56.495 C 268.111 57.775,275.593 61.586,277.253 62.633 C 278.095 63.165,278.863 63.600,278.960 63.600 C 279.225 63.600,285.387 67.444,288.800 69.739 C 318.044 89.397,343.243 124.127,351.389 156.000 C 351.530 156.550,352.180 159.070,352.835 161.600 C 359.008 185.468,359.063 215.798,352.973 238.286 C 352.658 239.449,352.124 241.435,351.787 242.700 C 350.419 247.835,345.902 260.530,345.140 261.380 C 344.953 261.589,344.800 262.002,344.800 262.298 C 344.800 264.039,335.871 280.409,330.672 288.200 C 310.477 318.462,277.024 342.642,242.700 351.787 C 241.435 352.124,239.455 352.655,238.300 352.966 C 215.591 359.086,185.072 359.017,161.400 352.791 C 158.760 352.097,156.060 351.392,155.400 351.226 C 115.139 341.064,74.511 305.679,56.399 265.000 C 54.373 260.448,54.082 259.755,53.799 258.800 C 53.636 258.250,53.360 257.620,53.187 257.400 C 52.920 257.062,49.589 247.251,48.434 243.400 C 47.592 240.593,45.591 231.870,44.856 227.800 C 44.084 223.521,43.617 219.918,42.798 211.916 C 42.285 206.906,42.287 194.174,42.801 188.569 C 43.391 182.140,44.004 176.841,44.395 174.800 C 44.584 173.810,44.947 171.830,45.201 170.400 C 46.514 162.993,50.768 148.149,53.167 142.600 C 53.547 141.720,54.180 140.190,54.572 139.200 C 68.793 103.334,102.113 69.798,138.200 55.029 C 139.740 54.398,141.540 53.657,142.200 53.380 C 144.109 52.580,148.772 50.892,151.600 49.978 C 155.921 48.579,165.148 46.148,168.200 45.603 C 176.659 44.092,179.958 43.618,187.400 42.839 C 192.820 42.272,207.365 42.357,213.600 42.992"
                stroke={"none"}
                fill={mainColor}
                fillRule="evenodd"
            />
            <path
                id="path2"
                d="M198.200 126.423 C 206.461 127.017,219.073 128.345,224.200 129.161 C 225.520 129.371,227.915 129.736,229.522 129.973 C 255.053 133.733,283.408 143.034,304.600 154.600 C 314.391 159.944,317.677 166.765,314.421 174.990 C 311.481 182.418,301.426 186.348,294.600 182.736 C 288.794 179.664,277.569 174.054,276.000 173.440 C 275.010 173.052,273.660 172.500,273.000 172.215 C 266.804 169.531,253.426 165.235,245.000 163.222 C 243.900 162.960,242.010 162.499,240.800 162.200 C 238.311 161.583,230.144 160.034,223.000 158.824 C 188.252 152.936,144.261 154.571,114.000 162.873 C 108.958 164.256,107.782 164.444,105.000 164.308 C 90.769 163.614,85.994 145.233,98.000 137.362 C 103.894 133.497,132.163 128.130,155.400 126.463 C 162.507 125.953,191.288 125.927,198.200 126.423"
                stroke={"none"}
                fill={mainColor}
                fillRule="evenodd"
            />
            <path
                id="path3"
                d="M186.400 180.388 C 207.619 181.494,228.147 185.299,247.645 191.740 C 269.746 199.041,289.479 209.309,292.119 214.882 C 297.781 226.838,284.397 237.530,273.200 229.995 C 269.779 227.693,258.460 222.084,252.200 219.590 C 227.358 209.690,200.987 204.787,172.668 204.805 C 154.125 204.817,137.925 207.004,119.200 212.023 C 114.147 213.378,112.424 213.379,109.370 212.028 C 98.810 207.357,99.521 192.781,110.492 189.057 C 129.803 182.502,160.244 179.026,186.400 180.388"
                stroke={"none"}
                fill={mainColor}
                fillRule="evenodd"
            />
            <path
                id="path4"
                d="M191.284 232.002 C 193.548 232.223,197.290 232.593,199.600 232.824 C 217.385 234.599,237.670 240.315,254.200 248.208 C 259.801 250.883,269.095 256.070,270.304 257.196 C 277.670 264.058,271.761 276.095,261.932 274.251 C 261.194 274.113,258.433 272.779,255.795 271.288 C 235.376 259.742,215.933 253.928,190.600 251.790 C 179.956 250.892,159.237 251.294,150.666 252.564 C 149.970 252.667,147.960 252.954,146.200 253.200 C 140.567 253.990,132.685 255.262,129.800 255.847 C 128.260 256.160,125.203 256.793,123.006 257.254 C 113.821 259.183,108.619 255.912,108.606 248.200 C 108.595 242.203,111.216 239.741,119.600 237.867 C 142.131 232.830,173.498 230.264,191.284 232.002"
                stroke={"none"}
                fill={mainColor}
                fillRule="evenodd"
            />
        </g>
    </svg>
);
