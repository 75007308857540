import React, { useContext, useEffect, useRef, useState } from 'react';
import { styled, Typography } from '@mui/material';
import { LanguageContext } from '../contexts/LocalizationContext/languages/LanguageContext';
import { ThemeContext } from '../contexts/ThemeContext';
import { YoutubePlayLogo } from "../media/YouTubePlayButton";
import { useLocation } from "react-router-dom";
import { LayoutContext } from "../contexts/LayoutContext/LayoutContext";
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import {ListenFollowShareBar} from "./ListenFollowShareBar";
import {MainVideoPlayerVideoData} from "../displayConfig";

function useIntersection(ref, options) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIntersecting(entry.isIntersecting);
        }, options);

        const currentRef = ref.current; // Store the current ref value

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, [ref, options]);

    return isIntersecting;
}

const KeepIframeAspectRatio = () => {
    const [containerWidth, setContainerWidth] = useState('100%');

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth > 1024) {
                setContainerWidth('70%');
            } else if (screenWidth > 600) {
                setContainerWidth('90%');
            } else {
                setContainerWidth('100%');
            }
        };

        handleResize(); // Call it initially to set the container width

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return containerWidth;
};

const Container = styled('div')(({ theme, containerWidth }) => ({
    position: 'relative',
    width: containerWidth,
    height: 0,
    paddingTop: `calc(${containerWidth} * 0.5625)`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    backgroundColor: '#000000',
    '& iframe': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
}));

const StyledDiv = styled('div')(({ theme, containerWidth, videos }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    backgroundImage: `url(${videos[0].ytThumbnail})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
}));


const ButtonsContainer = styled('div')(({ theme, containerWidth }) => ({
    color: '#ffffff',
    height: 35,
    width: containerWidth,
    display: 'flex',
    backgroundColor: '#b2071d',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
}));

const Controls = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    width: '25%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
}));

export const MainVideoPlayer = () => {
    const { dictionary } = useContext(LanguageContext);
    const { isMobile, isIOS } = useContext(LayoutContext)
    const location = useLocation();
    const { theme } = useContext(ThemeContext);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isImageHidden, setIsImageHidden] = useState(false);

    const videos = MainVideoPlayerVideoData();

    const handlePreviousVideo = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + videos.length) % videos.length);
    };

    const handleNextVideo = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % videos.length);
    };

    const handleVideoFromStart = () => {
        const iframe = document.querySelector('iframe[title="MainVideoPlayer"]');
        if (iframe) {
            const updatedSrc = iframe.src.replace(/start=\d+/g, 'start=0');
            iframe.src = updatedSrc;
            iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
        }
    };


    useEffect(() => {
        // Check if the location hash is '#main-video-player'
        if (location.hash === '#main-video-player') {
            //    setIsImageHidden(true);
        }
    }, [dictionary.previousVideo, location]);

    const containerWidth = KeepIframeAspectRatio();

    const ref = useRef();
    const isVisible = useIntersection(ref, { threshold: 1 });

    useEffect(() => {// hide image and play video when styledDiv is in view
        if (isVisible) {
            // setIsImageHidden(true);

        }
    }, [isVisible]);

    // const test = isIOS();
    // console.log('is ios', test)

    return (
        <>
            <Container containerWidth={containerWidth}>
                {!isImageHidden && isIOS() && (
                    setIsImageHidden(true)
                )}
                {!isImageHidden && (
                    <StyledDiv ref={ref} onClick={() => {
                        setIsImageHidden(true);
                    }} videos={videos}>
                        <div style={{ position: 'relative', top: '54%', width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <YoutubePlayLogo width={isMobile ? '15vw' : '9vw'} height={isMobile ? '15vw' : '9vw'} />
                        </div>
                    </StyledDiv>
                )}
                {isImageHidden && (
                    <iframe
                        title="MainVideoPlayer"
                        width="100%"
                        height="100%"
                        src={
                            videos[currentIndex].start !== undefined ?
                                `${videos[currentIndex].videoId}?autoplay=1&controls=1&modestbranding=0&fs=1&cc_load_policy=1&iv_load_policy=1&autohide=0&rel=0&start=${videos[currentIndex].start}&end=${videos[currentIndex].end}&vq=hd1080`
                                :
                                `${videos[currentIndex].videoId}&vq=hd1080`
                        }
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                    />
                )}
            </Container>
            <ButtonsContainer containerWidth={containerWidth}>
                <Controls theme={theme} sx={{justifyContent: 'right',}} onClick={ !isImageHidden ? () => setIsImageHidden(true) : handlePreviousVideo}>
                    <SkipPreviousIcon fontSize="large"/>
                </Controls>
                <Controls theme={theme} sx={{justifyContent: 'center', width: isMobile ? '50%' : '33.33%'}} onClick={ !isImageHidden ? () => setIsImageHidden(true) : handleVideoFromStart}>
                    <Typography className="resizable-typography" variant="h6">{dictionary.fullVideo}</Typography>
                </Controls>
                <Controls theme={theme} sx={{justifyContent: 'left',}} onClick={ !isImageHidden ? () => setIsImageHidden(true) : handleNextVideo}>
                    <SkipNextIcon fontSize="large"/>
                </Controls>
            </ButtonsContainer>
            <ListenFollowShareBar containerWidth={containerWidth}/>
        </>
    );
};