import React, {useContext, useState} from 'react';
import { Box, Link, Modal, Typography } from '@mui/material';
import {ThemeContext} from "../contexts/ThemeContext";
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import {citsEmailAddress, selvansPhoneNumber} from "../config";
import {LanguageContext} from "../contexts/LocalizationContext/languages/LanguageContext";

const Footer = () => {
    const { dictionary } = useContext(LanguageContext);
    const { theme } = useContext(ThemeContext)

    const [open, setOpen] = useState(false);

    const handleEmailClick = () => {
        window.location.href = `mailto:${citsEmailAddress}?subject=Performance Inquiry&body=Hello Camping In The Skies Team`;
    };

    const handlePhoneClick = async () => {
        try {
            await navigator.clipboard.writeText('+44 7763 348700');
            setOpen(true);
            setTimeout(() => {
                setOpen(false);
                window.location.href = 'tel:+44-7763-348700';
            }, 600);
        } catch (error) {
            console.error('Failed to copy phone number to clipboard:', error);
        }
    };


    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box component="footer" sx={{
            backgroundColor: theme.palette.background.default,
            opacity: 1,
            borderTop: '1px solid black',
            boxShadow: theme.shadows[20],
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            minHeight: '80px',
            bottom: 0,
        }}>
            <Link onClick={handleEmailClick} underline="none">
                <Box display="flex" alignItems="center" color={theme.palette.primary.main}>
                    <LocalPostOfficeIcon sx={{marginRight: 1}} />
                    <span>{citsEmailAddress}</span>
                </Box>
            </Link>
        </Box>
    );
};

export default Footer;
