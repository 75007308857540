import React, {createContext, useCallback, useEffect, useState} from 'react';

import { useWindowSize} from "../../shared/hooks/useWindowSize";

export const LayoutContext = createContext()

export const LayoutContextProvider = ({children}) => {

    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [ isMovingNavBarEnabled, setIsMovingNavBarEnabled] = useState(true);
    const [isNavBarVisible, setIsNavBarVisible] = useState(true);

    const handleScroll = useCallback(() => {
        const currentScrollPos = window.scrollY;
        setIsNavBarVisible(isMovingNavBarEnabled && (prevScrollPos > currentScrollPos || currentScrollPos === 0));
        setPrevScrollPos(currentScrollPos);
    }, [isMovingNavBarEnabled, prevScrollPos]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);

    }, [handleScroll]);

    const isMobile = useWindowSize().width < 832;
    const isTablet = useWindowSize().width < 1235 && !isMobile;
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const cardsPadding = isMobile ? '12px 0px' : '12px 12px'

    const isIOS = () => {
        const userAgent = navigator.userAgent;
        return /iPhone|iPad|iPod/i.test(userAgent);
    };

    return (
        <LayoutContext.Provider
            value={{
                isMobile,
                isTablet,
                isNavBarVisible,
                setIsNavBarVisible,
                setIsMovingNavBarEnabled,
                isIOS,
                screenWidth,
                cardsPadding,
            }}
        >
            {children}
        </LayoutContext.Provider>
    );
}
