// en.js
import {mermbersAndPartners} from "../../../config";

export const en = {
  hello: 'Hello',
  payment: 'Payment',
  listen: 'Listen',
  follow: 'Follow',
  share: 'Share',
  linkCopied: 'Link copied',
  copyLink: 'Copy link',
  previousVideo: 'Previous Video',
  fullVideo: 'Full Video',
  nextVideo: 'Next Video',
  donateWithPaypal: 'Donate with PayPal',
  bandcampAlbumPunchline: 'Discover the album of the year!',
  phoneNumberCopied: 'Number copied',
  lyrics: 'Lyrics',
  story: 'Story',
  credits: 'Credits',
  originalLyrics: 'Original lyrics',
  translatedToLanguageContext: 'Translated lyrics',
  disobeyLyrics: `Disobey\nWe were born to disobey everyone\nNever liked what mother nature told us\nWe made our way to sit in the throne of her\nWe play to have the power like a god\n\nAnd we learned that to create\nYou need to destroy\nDeploy your envoy\nTo build your new toy\n\nOooh\n\nDon’t let them break you down\nDon’t break anyone down\n\nLet everyone be who they are\nGreat is he who finds beauty\nIn our hearts\n\nThey say to be free follow their way\nAll around you tricks to keep you tamed\nThey want you soft\nTo mould You like clay\nThey even conceive\nLaws to keep you scared\n\nAnd we learned that to create\nYou need to destroy\nDeploy your envoy\nTo build your new toy\n\nOooh\n\nDon’t let them break you down\nDon’t break anyone down\n\nLet everyone be who they are\nGreat is he who finds beauty\nIn our hearts\nIn our hearts\n\nDon’t stop defying the unjust\nStand your ground\nFreedom is based on this fight\n\nDon’t stop defying the unjust\nStand your ground\nFreedom is based on this fight\n\nDon’t stop defying the unjust\nStand your ground\nFreedom is based on this fight\n\nDon’t stop defying the unjust\nStand your ground\nFreedom is based on this fight\n\nLet everyone be who they are\nGreat is he who finds beauty\nIn our hearts\nIn our hearts\nIn our hearts\n\nDon’t let them break you down\nDon’t break anyone down\nLet everyone be who they are\nGreat is he who finds beauty\nIn our hearts`,
  movingOnLyrics: `Moving on, I’m moving on\nCall me weak or strong but I’m moving on\nStop if everything goes wrong, Moving on\nStart again It won’t take long, Moving on\nI’m gone\n\nYou come and leave like if I was a drive by\nCan’t make you stay longer as much as I try\nYou just stop a while and get what you want\nThen you leave like if you’ve never stopped\n\nMoving on, I’m moving on\nCall me weak or strong but I'm moving on\nStop if everything goes wrong, Moving on\nStart again it won’t take long, Moving on\nI’m gone\n\nBack on my own, Sitting on my throne\nI’ll take it slow\n\nI feel like I ate something wrong\nCuz you turned out to be the worst, the worst\nYou make wonder to myself\nWhat I’ve been doing these years\nI fell into Lethargy\nAnd so I drop back home\n\nNever to be seen again\nI wish\nthat I could see you back here\n\nI’m burning down the bridge\nI’m burning down the bridge\nThat\nConnected me to you\n\nMoving on, I’m moving on\nCall me weak or strong but I’m moving on\nStop if everything goes wrong, Moving on\nI’m gone\nMoving on`,
  citsLyrics: `There’s a boy who doesn’t stop\nForming projects commits to none\nHe will try to get more done\nBut if he stops he’ll accomplish none\nAll that eyes look to see\nShow him more than what can be\nAnd when he starts hours fly\nHe lives beyond time\nHe belongs to the skies\n\nHe is a kid of light. He’s part of all that\nthis universe it’s made from. Made from\n\nSorcerer once had him blind\nHe was playing games with his mind\ndomesticating the light within\nThe boy thought these limits were a part of him\nUntil he was saved by a star\nshe redeemed him from the charm\nHe realised he lived in lies\n\nHe can now thrive in darkness and light\nHe’s part of all, gives all\nto build and expand his universe\nGive all, part of all,his universe\nThis kid of light will never fall\nhe carries all, he carries all\n\nYou’ll give all up to your call\nconstant like a waterfall\nFear you’ll have no more\nWild you're waves will make them fall\nAnd you’ll give all, all\n\nSorcerers lurking seeking waiting for the next prey\nSorcerers lurking seeking waiting for the next prey\nSorcerers lurking seeking waiting for the next prey\nSorcerers lurking seeking waiting for the next prey\n\nYou will lose it become useless\nthat’s what you get you’re not meant\nto dominate to manipulate\nYou will lose it become useless\nthat’s what you get you’re not meant\nto dominate to manipulate\n\nWe’ll free all free all\nWe’ll free all free all\nWe’ll free all, all\nFree all\nYou give all up to your call constant like a waterfall\nFear you’ll have no more\nWild you're waves will make them fall\nFree all, free all`,
  exploreSongs: 'Explore Songs',
  purchaseNow: 'Purchase Now',
  watchAndFeel: 'Watch & Feel',
  creditsRodScottBeedieMatt: `Vocals and lyrics: ${mermbersAndPartners.mattSelvansFullName}.\nGuitar: ${mermbersAndPartners.rodaidhFullName}.\nBass Guitar: Scott Davie.\nDrums: ${mermbersAndPartners.liamFullName}.\nMixed and produced by ${mermbersAndPartners.rodaidhFullName}.\nDrums tracked at Captain Toms.`
};
