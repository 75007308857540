import React from 'react';
import {newsletterHtml} from "./NewsletterHTML";
import {Typography} from "@mui/material";
import {OgMetaTagsPages} from "../ReusableComponents/OgMetaTags";

export const Newsletter = ({ pageMeta }) => {

    return(
        <div style={{ height: '100vh', backgroundColor: '#E0E0E0'}}>
            <OgMetaTagsPages content={pageMeta} />
            <br/>
            <br/>
            <Typography variant="h3" textAlign="center" >Stay Tuned!</Typography>
            <Typography variant="body1" textAlign="center" >Subscribe to our newsletter to stay informed on our major releases. some of them will be only published on this page.</Typography>
            <div dangerouslySetInnerHTML={{ __html: newsletterHtml }} />
        </div>
    )

}