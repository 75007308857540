import { createUseStyles } from 'react-jss';
import React, {useContext, useEffect, useState} from "react";
import {LayoutContext} from "../contexts/LayoutContext/LayoutContext";
import {styled} from "@mui/material";
import {useSwipeable} from "react-swipeable";
import {SwipeHint} from "./SwipeHint";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {MainVideoPlayerVideoData} from "../displayConfig";

// eslint-disable-next-line no-unused-vars
/* global YT */

const useStyles = createUseStyles(() => ({
    storiesContainer: {
        height: 205,
        width: '100%',
        display: 'flex',
        overflowX: 'auto',
        justifyContent: 'flex-start',
        backgroundColor: '#0a0a0a',
        alignItems: 'center',
    },
    storyLogo: {
        backgroundColor: '#fff',
        width: 30,
        height: 30,
        boxShadow: '0 0 0 1px white',
        borderRadius: '50%',
        float: 'right',
        marginRight: 2,
        marginTop: 2,
    },
    storyTitle: {
        position: 'absolute',
        bottom: 0,
        fontSize: '100%',
        WebkitTextStroke: '1px #677d99',
        textStroke: '1px #677d99',
        textAlign: 'center',
        width: '100%',
        left: 0,
        right: 0,
        margin: '0 auto',
    },
    storiesFloatingWindow: {
        display: 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#000000',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999,
    },
    iframeContainer: {
        width: '100%',
        height: '100%',
        position: 'fixed',
        margin: '0 auto',
        left: '0',
        bottom: '0',
        right: '0',
        display: 'flex',
    },
    iframe:{
    },
    storyCards: {
        position: 'relative',
        flex: '0 0 auto',
        flexDirection: 'column',
        marginLeft: 5,
        width: 112,
        height: '95%',
        backgroundColor: '#677d99',
        borderRadius: 5,
        color: 'white',
        cursor: 'pointer',
        fontSize: '100%',
        textAlign: 'center',
    },
    youtubeControlsContainer: {
        // borderStyle: 'solid',
        height: '15%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999,
    },
    buttonsContainer: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 999,
    },
    previousButton: {
        display: 'flex',
        bottom: 0,
        width: '30%',
        height: '100%',
        // borderStyle: 'solid',
        cursor: 'pointer',
        justifyContent: 'flex-start',
        zIndex: 999,
    },
    nextButton: {
        display: 'flex',
        bottom: 0,
        width: '30%',
        height: '100%',
        // borderStyle: 'solid',
        cursor: 'pointer',
        justifyContent: 'flex-end',
        zIndex: 999,
    },
    watchFullVidButton: {
        display: 'flex',
        bottom: 0,
        width: '30%',
        height: '100%',
        borderStyle: 'solid',
        cursor: 'pointer',
        borderRadius: 0,
        fontSize: '100%',
        zIndex: 999,
    },
    closeButton: {
        display: 'flex',
        width: 100,
        height: 100,
        alignItems: 'start',
        justifyContent: 'flex-end',
        fontSize: 35,
        color: 'white',
        cursor: 'pointer',
        zIndex: 1000,
    },
    topRightCorner: {
        position: "absolute",
        top: -10,
        right: 3,
    },
}));

function capitalizeAndRemoveSpaces(str) {
    return str.split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.substring(1))
        .join('');
}

const Container = styled('div')(({ containerWidth }) => ({
    position: 'relative',
    width: containerWidth,
    height: 0,
    paddingTop: `calc(${containerWidth} * 0.5625)`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    backgroundColor: '#000000',
    '& iframe': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
}));

export const Stories = () => {
    // const { dictionary } = useContext(LanguageContext);
    const { isMobile, isIOS } = useContext(LayoutContext);
    // const location = useLocation();

    const logoSize = 30

    const [storyCurrentIndex, setStoryCurrentIndex] = useState(0);
    const [openStoriesFloatingWindow, setOpenStoriesFloatingWindow] = useState(false);
    const [playStory, setPlayStory] = useState(false);
    const [isStoryVideoPaused, setIsStoryVideoPaused] = useState(false);
    const [isYouTubeControlsEnabled, setIsYouTubeControlsEnabled] = useState(false);
    const [showSwipeHint, setShowSwipeHint] = useState(false);
    const [hasSwipeHintShown, setHasSwipeHintShown] = useState(false);

    const videos = MainVideoPlayerVideoData();

    function handlePlayPause() {
        setIsStoryVideoPaused(prevState => !prevState);
        const iframe = document.querySelector('iframe');
        const func = isStoryVideoPaused ? 'pauseVideo' : 'playVideo';
        iframe.contentWindow.postMessage(`{"event":"command","func":"${func}","args":""}`, '*');
    }

    const toggleYouTubeControls = () => {
        setIsYouTubeControlsEnabled(prevState => {
            if (!prevState) {
                setTimeout(() => {
                    setIsYouTubeControlsEnabled(false);
                }, 7000);
            }
            return !prevState;
        });
    };


    const handlePreviousVideo = () => {
        setStoryCurrentIndex((prevIndex) => (prevIndex - 1 + videos.length) % videos.length);
    };

    const handleNextVideo = () => {
        setStoryCurrentIndex((prevIndex) => (prevIndex + 1) % videos.length);
    };


    const handleVideoFromStart = () => {
        const iframe = document.querySelector('iframe');
        iframe.src = iframe.src.replace(/start=\d+/g, 'start=0');
        iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
    };

    // useEffect(() => {
    // Check if the location hash is '#main-video-player'
    // if (location.hash === '#main-video-player') {
    //    setOpenStoriesFloatingWindow(true);
    //     }
    // }, [dictionary.previousVideo, location]);

    const { setIsNavBarVisible, setIsMovingNavBarEnabled } = useContext(LayoutContext)
    const classes = useStyles();

    const openStoryWindow = () => {
        setPlayStory((prevState => true))
        const window = document.getElementById('storiesFloatingWindow');
        window.style.display = 'flex';
        setIsNavBarVisible(false);
        setIsMovingNavBarEnabled(false);
        setShowSwipeHint(true);

        setTimeout(() => {
            setShowSwipeHint((prevShowSwipeHint) => {
                return false;
            })
            setHasSwipeHintShown((prevHasSwipeHintShown) => {return true;});

        }, 1000);

        // Request full-screen mode on iOS devices
        // if (isIOS) {
        //     const iframe = document.getElementById('player');
        //     iframe.webkitRequestFullscreen(); // Request full-screen for iOS
        // }

    };

    const closeStoryWindow = () => {
        handlePlayPause()
        setPlayStory((prevState => false))
        const window = document.getElementById('storiesFloatingWindow');
        const iframe = document.querySelector('iframe');
        iframe.contentWindow.postMessage(`{"event":"command","func":"pauseVideo","args":""}`, '*');
        window.style.display = 'none';
        setIsNavBarVisible(true);
        setIsMovingNavBarEnabled(true);
    };

    const handleGoBack = () => {
        closeStoryWindow();
    };

    const handleKeyPress = (event) => {
        if (event.key === "Escape") {
            closeStoryWindow();
        }
    };

    // esc and back listeners
    useEffect(() => {
        window.addEventListener("popstate", handleGoBack);
        window.addEventListener("keydown", handleKeyPress);

        return () => {
            window.removeEventListener("popstate", handleGoBack);
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleGoBack, handleKeyPress]);

    const onPlayerReady = (event) => {
        const playerState = event.target.getPlayerState();

        if (playerState === window.YT.PlayerState.PLAYING) {
            setIsStoryVideoPaused(false);
        } else {
            setIsStoryVideoPaused(true);
        }

        const availableQualityLevels = event.target.getAvailableQualityLevels();
        if (availableQualityLevels.includes('highres')) {
            event.target.setPlaybackQuality('highres');
        } else {
            event.target.setPlaybackQuality('hd1080');
        }
    };

    const [isYouTubeAPIReady, setIsYouTubeAPIReady] = useState(false);

    useEffect(() => {
        // Load the YouTube Iframe API script and define the global function 'onYouTubeIframeAPIReady'
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubeIframeAPIReady = () => {
            // Add a log to check if the function is triggered
        };
    }, []);

    const onPlayerStateChange = (event) => {
        const playerState = event.data;

        if (playerState === window.YT.PlayerState.ENDED) {
            handleNextVideo();
            handleVideoFromStart();
        }
    };

    useEffect(() => {
        if (window.YT && window.YT.Player) {
            new window.YT.Player('player', {
                events: {
                    onReady: onPlayerReady,
                    onStateChange: onPlayerStateChange,
                },
            });
        }
    }, [isYouTubeAPIReady, onPlayerStateChange]);

    const handlers = useSwipeable({
        onSwipedLeft: () => {
            // Handle left swipe
            !openStoriesFloatingWindow ? setOpenStoriesFloatingWindow(true) : handlePreviousVideo()
        },
        onSwipedRight: () => {
            // Handle right swipe
            !openStoriesFloatingWindow ? setOpenStoriesFloatingWindow(true) : handleNextVideo()
        }
    });

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <div id="storiesFloatingWindow" className={classes.storiesFloatingWindow} >
                {!hasSwipeHintShown && showSwipeHint && (
                    <div style={{width: '100%', height: '100%', position: 'absolute', zIndex: 1000}}>
                        <SwipeHint/>
                    </div>
                )}





                {!isYouTubeControlsEnabled && (
                    <>
                        <div {...handlers} className={classes.buttonsContainer} onClick={ !openStoriesFloatingWindow ? () => setOpenStoriesFloatingWindow(true) : handlePlayPause}>
                            <div className={classes.previousButton}>
                                <div
                                    style={{ display: 'flex', position: 'fixed', top: 'calc(50vh)', width: 40,height: 60, backgroundColor: 'rgba(75,75,75,0.3)', alignItems: 'center', zIndex: 1001}}
                                    onClick={handlePreviousVideo}
                                >
                                    <ArrowBackIosIcon sx={{fontSize: 30, marginLeft: 1}} />
                                </div>
                            </div>
                            <div className={`${classes.closeButton} ${classes.topRightCorner}`} onClick={() => closeStoryWindow()}> X </div>
                            {/*<div className={classes.watchFullVidButton} style={{justifyContent: 'center', width: '33.33%'}} onClick={ !openStoriesFloatingWindow ? () => setOpenStoriesFloatingWindow(true) : handleVideoFromStart}/>*/}
                            <div className={classes.nextButton}>
                                <div
                                    style={{ display: 'flex', position: 'fixed', top: 'calc(50vh)', width: 40,height: 60, backgroundColor: 'rgba(75,75,75,0.3)', alignItems: 'center', zIndex: 1001,justifyContent: 'right'}}
                                    onClick={handleNextVideo}
                                >
                                    <ArrowForwardIosIcon sx={{fontSize: 30}} />
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {isIOS}
                <Container containerWidth={isMobile ? '100%' : '175vh'}>
                    { playStory &&(
                        <iframe
                            id="player"
                            title="YouTube video player"
                            width="100%"
                            height="100%"
                            src={
                                videos[storyCurrentIndex].start !== undefined ?
                                    `${videos[storyCurrentIndex].videoId}?autoplay=1&controls=1&modestbranding=0&fs=1&cc_load_policy=1&iv_load_policy=1&autohide=0&rel=0&start=${videos[storyCurrentIndex].start}&end=${videos[storyCurrentIndex].end}&vq=hd1080&enablejsapi=1`
                                    :
                                    `${videos[storyCurrentIndex].videoId}&vq=hd1080`
                            }
                            frameBorder="0"
                            allow="autoplay; encrypted-media"
                            // allowFullScreen
                        />
                    )}
                    {!isYouTubeControlsEnabled && (
                        <div className={classes.youtubeControlsContainer} onClick={() => toggleYouTubeControls()}/>
                    )}
                </Container>
            </div>
            <div
                id="stories-container"
                className={classes.storiesContainer}
            >
                {videos.map((video, i) => {
                    return (
                        <div
                            key={i}
                            id={`${capitalizeAndRemoveSpaces( video.name )}-story-button`}
                            className={classes.storyCards}
                            style={{
                                backgroundImage: `url(${video.ytThumbnail})`,
                                backgroundSize: "contain",
                                // backgroundRepeat: "no-repeat",
                                // backgroundPosition: "center",
                            }}
                            onClick={() => {
                                setStoryCurrentIndex((prevState) => i)
                                // handleVideoFromStart()
                                openStoryWindow()
                            }}
                        >
                            <div className={classes.storyLogo}>{React.cloneElement(video.logo, {size: logoSize})}</div>
                            <h3 className={classes.storyTitle}>{video.name}</h3>
                        </div>
                    );
                })}
            </div>
            <div id="console-log" style={{ display: "none" }} />
        </div>
    );
};