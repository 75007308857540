import React from "react";
import {OgMetaTagsPages} from "../ReusableComponents/OgMetaTags";
import MuseArtwork from '../../media/songsArtwork/MuseArtwork.jpg'
import { FaDeezer } from "react-icons/fa";
import { PiSpotifyLogoDuotone } from "react-icons/pi";
import { SiYoutubemusic } from "react-icons/si";
import { AiFillAmazonCircle } from "react-icons/ai";
import { SiApplemusic } from "react-icons/si";


export const MuseLinks = ({pageMeta}) => {

   const songLinks = [
       {platformName: 'Spotify', link: 'https://open.spotify.com/album/6cTvXYkR4yvpim4jVnx0eu?si=6xvCWNLLRvm2ARTuqMrlVw', icon: <PiSpotifyLogoDuotone />,},
       {platformName: 'YouTube Music', link: 'https://music.youtube.com/playlist?list=OLAK5uy_mL7ZeWpKu4wWg-Jbefu9fAdDvDaTe5H_E&si=v5khXFlmawgUi9E9', icon: <SiYoutubemusic />,},
       {platformName: 'Amazon Music', link: 'https://music.amazon.es/albums/B0CK732HK4', icon: <AiFillAmazonCircle />,},
       {platformName: 'Apple Music', link: 'https://music.apple.com/us/album/muse/1709866763?i=1709866764', icon: <SiApplemusic />,},
       {platformName: 'Deezer', link: 'https://www.deezer.com/album/495544041', icon: <FaDeezer />,},
       // {platformName: 'YouTube', link: 'https://youtu.be/sRG2pSy7_9I?si=Bth6pLYb8MVQ0apP'},
   ]

    return(
        <div
            style={{
                backgroundImage: `url("${MuseArtwork}")`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                width: '100%',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <OgMetaTagsPages content={pageMeta} />
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', width: '50%' }}>
                {songLinks.map((song, index) => (
                    <a
                        key={index}
                        href={song.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none', color: '#ffffff', fontWeight: 'bolder' }}
                    >
                        <div
                            style={{
                                backgroundColor: 'rgba(86,86,86,0.6)',
                                borderRadius: '10px',
                                margin: '10px',
                                padding: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <span style={{ marginRight: '5px', display: 'flex', alignItems: 'center', fontSize: 35 }}>{song.icon}</span>
                            {song.platformName}
                        </div>
                    </a>
                ))}
            </div>
        </div>
    )
}