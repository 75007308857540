import { createUseStyles } from 'react-jss';
import React, {useContext, useEffect, useState} from "react";
import {Box, Button, styled} from "@mui/material";
import {useLocation} from "react-router-dom";
import {BackgroundPlayerData} from "../displayConfig";
import {Link} from "react-router-dom";
import {LayoutContext} from "../contexts/LayoutContext/LayoutContext";
import {citsBandcamp} from "../config";
import {LanguageContext} from "../contexts/LocalizationContext/languages/LanguageContext";
import {ThemeContext} from "../contexts/ThemeContext";



// eslint-disable-next-line no-unused-vars
/* global YT */

const useStyles = createUseStyles(() => ({
    backgroundVideoContainer: {
        display: 'flex',
        width: '100%',
        height: '100%',
        backgroundColor: '#000000',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    disableFrameControlsBackgroundVideoContainer: {
        height: '100%',
        width: '100%',
        top: 0,
        display: 'flex',
        position: 'absolute',
        justifyContent: 'space-between',
        alignItems: 'center',
        left: 0,
        right: 0,
        zIndex: 999,
    },
    disableFrameControlsBackgroundVideo: {
        display: 'flex',
        bottom: 0,
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        justifyContent: 'flex-start',
        zIndex: 999,
    },
}));

const ContainerBackgroundVideo = styled('div')(({ containerWidth }) => ({
    position: 'relative',
    width: containerWidth,
    height: 0,
    paddingTop: `calc(${containerWidth} * 0.5625)`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    backgroundColor: '#000000',
    '& iframe': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
}));

const MyButton = styled(Button)(({ theme }) => ({
    width: '25%',
    border: '2px solid white',
    borderColor: '#fff',
    [theme.breakpoints.up("md")]: {
        fontSize: 20
    },
    [theme.breakpoints.down("md")]: {
        width: '45%',
        opacity: 0.6,
    },
    [theme.breakpoints.down("sm")]: {
        width: '48%',
        opacity: 0.6,
        fontSize: 13,
    },
}));

export const BackgroundVideo = () => {
    const { theme } = useContext(ThemeContext);
    const { isMobile } = useContext(LayoutContext);
    const { dictionary } = useContext(LanguageContext);

    const location = useLocation()

    const [currentIndexBackgroundVideo, setCurrentIndexBackgroundVideo] = useState(0);
    const [isVideoPaused, setIsVideoPaused] = useState(false);

    const videos = BackgroundPlayerData();

    function playBackgroundVideo() {
        const iframe = document.querySelector('iframe');
        iframe.contentWindow.postMessage(`{"event":"command","func":"playVideo","args":""}`, '*');
    }

    const classes = useStyles();

    const onBackgroundPlayerReady = (event) => {
        const playerState = event.target.getPlayerState();

        // Check if the video is already playing
        if (playerState === window.YT.PlayerState.PLAYING) {
            setIsVideoPaused(false);
        } else {
            // Video is paused or ended
            setIsVideoPaused(true);
        }

        const availableQualityLevels = event.target.getAvailableQualityLevels();
        if (availableQualityLevels.includes('highres')) {
            event.target.setPlaybackQuality('highres');
        } else {
            event.target.setPlaybackQuality('hd1080');
        }
    };

    const [isYouTubeAPIReady, setIsYouTubeAPIReady] = useState(false);

    useEffect(() => {
        // Load the YouTube Iframe API script and define the global function 'onYouTubeIframeAPIReady'
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubeIframeAPIReady = () => {
            // Add a log to check if the function is triggered
        };
    }, []);

    const handleNexBackgroundVideo = () => {
        setCurrentIndexBackgroundVideo((prevIndex) => (prevIndex + 1) % videos.length);
    };

    const onBackgroundPlayerStateChange = (event) => {
        const playerState = event.data;

        if (playerState === window.YT.PlayerState.ENDED) {
            handleNexBackgroundVideo();
        } else if (playerState === window.YT.PlayerState.PLAYING && isVideoPaused) {
        }
    };

    useEffect(() => {
        if (window.YT && window.YT.Player) {
            new window.YT.Player('player', {
                events: {
                    onReady: onBackgroundPlayerReady,
                    onStateChange: onBackgroundPlayerStateChange,
                },
            });
        }
    }, [isYouTubeAPIReady, onBackgroundPlayerStateChange]);

    useEffect(() => {
        //Check if the location hash is '#main-video-player'
        if (location.hash === '#album') {
            playBackgroundVideo()
        }
    }, [location]);

    return (
        <div className={classes.backgroundVideoContainer} >
            <ContainerBackgroundVideo containerWidth={'100%'}>
                <div className={classes.disableFrameControlsBackgroundVideoContainer} >
                    <div className={classes.disableFrameControlsBackgroundVideo} onClick={ () => playBackgroundVideo()}/>
                </div>
                <iframe
                    id="player"
                    title="YouTube video player"
                    width="100%"
                    height="100%"
                    src={
                        `${videos[currentIndexBackgroundVideo].videoId}?autoplay=1&playsinline=1&controls=0&modestbranding=0&fs=1&cc_load_policy=0&iv_load_policy=1&autohide=0&mute=1&start=${videos[currentIndexBackgroundVideo].start}&end=${videos[currentIndexBackgroundVideo].end}&vq=hd1080&enablejsapi=1`
                    }
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    // allowFullScreen
                />
                <div style={{ width: '100%', position: 'absolute', top: isMobile ? '75%' : '47%', zIndex: 10000 }}>
                    <Box sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly',}}
                    >
                        <MyButton component={Link} to={`/discography`} variant="contained" sx={{ backgroundColor: theme.palette.secondary.main, color: '#fff' }}>
                            Watch More
                            {/*{dictionary.exploreSongs}*/}
                        </MyButton>
                        {/*<MyButton onClick={() => {window.open(citsBandcamp, '_blank');}} variant="contained" sx={{ backgroundColor: '#000000', color: '#fff' }}>*/}
                        {/*    {dictionary.purchaseNow}*/}
                        {/*</MyButton>*/}
                    </Box>
                </div>

            </ContainerBackgroundVideo>
        </div>
    );
};