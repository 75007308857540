import React, { createContext, useState } from 'react';
import {ThemeProvider} from "@mui/material";
import {darkTheme, lightTheme} from "../styles/muiTheme";



export const ThemeContext = createContext();

export function ThemeContextProvider(props) {
    //TODO find way to add to seo info that dark/light mode adapts to browser's config
    const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;

    const [theme, setTheme] = useState(
        prefersDarkMode ? darkTheme : lightTheme
    );

    const toggleTheme = () => {
        setTheme((prevTheme) =>
            prevTheme === lightTheme ? darkTheme : lightTheme
        );
    };

    const isDarkMode = theme === darkTheme;

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme, isDarkMode }}>
            <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
        </ThemeContext.Provider>
    );
}