import React, {Fragment, useEffect} from "react";
import {Routes, Route, Link, useLocation, Navigate } from "react-router-dom";
import {PagesList, PhotoAlbumList, routes, songLinksList} from "../config";
import {Homepage} from "../components/Homepage";
import NavBar from "../components/navbar/NavBar";
import Footer from "../components/Footer";
import {Discography} from "../components/discography/Discography";
import {Stories} from "../components/Stories";
import HomePageAboutUsCarousel from "../components/HomePageAboutUsCarousel";
import PartnersCarousel from "../components/PartnersCarousel/PartnersCarousel";
import {partnersDataArray} from "../displayConfig";
import {Newsletter} from "../components/Newsletter/Newsletter";
import {PhotoGallery} from "../components/PhotoGallery";
import {PhotoAlbum} from "../components/ReusableComponents/PhotoAlbum";
import {VideoGallery} from "../components/VideoGallery";
import {MuseLinks} from "../components/discography/MuseLinks";
import {OgMetaTagsPages} from "../components/ReusableComponents/OgMetaTags";

const RoutesAndLinksReactSnap = ({ path, element, redirect}) => {

    const location = useLocation();

    //onPageChange scroll all the way up
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [location]);

    return(
        <Fragment>
            <Routes>
                <Route path={path} element={element} />
            </Routes>
            { redirect ?
                <Navigate to={redirect} />
                :
                <Link to={path} style={{ display: 'none'}} />
            }
        </Fragment>
    )
}

export const Root = () => {

    return(
        <Fragment>
            {/*<Homepage/>*/}
            <NavBar/>
            <div style={{marginTop: 67}}/>
            <RoutesAndLinksReactSnap path={PagesList.homepage.route} element={<Homepage pageMeta={PagesList.homepage}/>} />
            {/*<RoutesAndLinksReactSnap path={PagesList.homebarGig.route} element={<Homepage pageMeta={PagesList.homebarGig}/>} />*/}
            <RoutesAndLinksReactSnap path={PagesList.newsletter.route} element={<Newsletter pageMeta={PagesList.newsletter} />} />
            <RoutesAndLinksReactSnap path={PagesList.photos.route} element={<PhotoGallery pageMeta={PagesList.photos} />} />
            <RoutesAndLinksReactSnap path={PagesList.videos.route} element={<VideoGallery pageMeta={PagesList.videos} />} />
            { PhotoAlbumList.map((album) =>(
                <RoutesAndLinksReactSnap key={album.url} path={album.url} element={<PhotoAlbum PhotoAlbum={album} />} />
            ))}
            <RoutesAndLinksReactSnap path={PagesList.aboutUs.route} element={<HomePageAboutUsCarousel pageMeta={PagesList.aboutUs} />} />
            <RoutesAndLinksReactSnap path={PagesList.discography.route} element={<Discography pageMeta={PagesList.discography} />}/>
            <RoutesAndLinksReactSnap path={songLinksList.muse.route} element={<MuseLinks pageMeta={songLinksList.muse} />}/>
            {/*<RoutesAndLinksReactSnap path={routes.partners} element={<PartnersCarousel members={partnersDataArray}/>} />*/}
            {/*<RoutesAndLinksReactSnap path={routes.stories} element={<Stories />} />*/}
            <Footer/>
        </Fragment>
    )
};
