import BannermansBarApril2019AlbumCover from "./media/pictures/gigs/BannermansBarApril2019AlbumCover.jpg";
import {BannermanApril19List} from "./media/pictures/gigs/Bannermans-april-2019/BannemanGigApril2019";
import KrakatoaMay2023AlbumCover from "./media/pictures/gigs/KrakatoaMay2023AlbumCover.jpg";
import {KrakatoaMay23List} from "./media/pictures/gigs/krakatoa-may-2023/KrakatoaMay23List";
import shelterCover from '../src/media/pictures/cits-shelter-cover.jpg';
import HomebarGigSquare from "./media/pictures/gigs/Homebar-Gig-Square.jpg";
import MuseArtwork from './media/songsArtwork/MuseArtwork.jpg'

import {socialMediaIcons} from "./displayConfig";

export const homepage = 'http://campingintheskies.web.app'
export const citsWebsite = 'https://campingintheskies.com/'
export const campingInTheSkies = 'Camping In The Skies'
export const shareOnWhatsAppThe = 'whatsapp://send?text='
export const citsBandcamp = 'https://campingintheskies.bandcamp.com/'
export const youTubeMusicCits = 'https://music.youtube.com/watch?v=lhSez-e0NuY'
export const amazonMusicCits = 'https://music.amazon.com/albums/B0BN8QSGBC'
export const appleMusicCits = 'https://music.apple.com/gm/artist/camping-in-the-skies/1642724146'
export const spotifyCits = 'https://open.spotify.com/album/0EcSXSkPNQUeHho9u0fJA9'
export const followOnFacebookCits = 'https://www.facebook.com/campingintheskies'
export const followOnInstagramCits = 'https://www.instagram.com/campingintheskies/'
export const followOnYouTubeCits = 'https://www.youtube.com/channel/UC4X7q03_DAWZL3X0mzYKOYQ?sub_confirmation=1'
export const followOnTikTokCits = 'https://www.tiktok.com/@campingintheskies'
export const followOnTwitterCits = 'https://campingintheskies.com/wp-content/uploads/2023/04/share-listen-follow-Twitter.png'
export const shareFacebookCits = 'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fcampingintheskies.com%2F&src=sdkpreparse'
export const shareTwitterCits = 'https://campingintheskies.com/wp-content/uploads/2023/04/share-listen-follow-Twitter.png'
export const donatePaypalMattSelvans = 'https://www.paypal.com/donate/?hosted_button_id=B2TEVJ54JCBJ6'
export const citsEmailAddress = 'campingintheskies@gmail.com'
export const unicornsIconsCredit = 'https://iconscout.com/'
export const everythingStartsOfficialCover = 'https://f4.bcbits.com/img/a3195052058_10.jpg'
export const youTubePlaylistCITS = 'https://www.youtube.com/watch?v=FTlNNAjyDr0&list=PLWVpjoVPTvc6C5hJbvdIehMzc8tgaXSUy&index=1'
export const youTubeChannel = 'https://www.youtube.com/@campingintheskies'
export const paypalDonateLink = 'https://www.paypal.com/donate/?hosted_button_id=SBL9E2MQN3RVE'

export const mermbersAndPartners = {
 scottFullName: 'Scott Davie',
 domFullName: 'Dom Owtram',
 omarFullName: 'Omar Daniel Docherty',
 mattSelvansFullName: 'Matt Selvans',
 rodaidhFullName: 'Rodaidh Mackay',
 bijayFullName: 'Bijay Shrestha',
 monniFullName: 'Monni Kamenov',
 liamFullName: 'Liam Beedie',
 mattJollyFullName: 'Matt Jolly',
}

export const PagesList = {
      discography: {
          route: '/discography',
          pageName: `Discography ${campingInTheSkies}`,
          ogImage: shelterCover,
          description: 'Delve into the Discography of Camping In The Skies, a testament to their unwavering commitment to their craft. Each track is born out of sleepless nights and long shifts, a dance with uncertainty and creativity. From this process, emerges music that’s polished, well-thought-out, and filled with soul. Their enticing lyrics resonate in every tune, reflecting their dedication. Experience the fruits of their labor at ‘Camping in the Skies’',
          keywords: 'Discography, Alt Rock Band, Self-Produced Music, Empowering Lyrics, Encouraging Music, Sleepless Nights, Long Shifts, Masterpieces, Musical Excellence, Polished Tracks, Soulful Music, Edinburgh and Tenerife, Online Collaboration, In-Person Collaboration, Original Music Videos, Original Photos, Camping in the Skies, Passionate Creations, Inspirational Tunes, Creative Journey, Independent Label'
      },
      homepage: {
          route: '/',
          pageName: `Homepage ${campingInTheSkies}`,
          ogImage: shelterCover,
          description: 'Immerse yourself in Camping in the Skies, an alt rock band based between Edinburgh and Tenerife. The band\'s work transcends geographical boundaries as they collaborate both online and in-person. Discover their unique blend of creativity and learn all about the band at ‘Camping in the Skies’ official website.',
          keywords: 'Immerse yourself in Camping in the Skies, an alt rock band based between Edinburgh and Tenerife. The band\'s work transcends geographical boundaries as they collaborate both online and in-person. Discover their unique blend of creativity and learn all about the band at ‘Camping in the Skies’ official website.'
      },
      homebarGig: {
          route: '/hbg',
          pageName: `Homebar Gig Thursday 23rd of may ${campingInTheSkies}`,
          ogImage: HomebarGigSquare,
          description: `Come and hear the future and past singles of ${campingInTheSkies} at Homebar Edinburgh`,
          keywords: `Gig, Scotland, Singles, alt-rock, Homebar, 69 home street, ${campingInTheSkies}`
      },
      aboutUs: {
          route: '/about-us',
          pageName: `About ${campingInTheSkies}`,
          ogImage: shelterCover,
          description: 'Camping In The Skies: an alt rock band based between Scotland and Tenerife. Learn more about their unique creative partnership, and their musical journey.',
          keywords: 'About Us, Camping In The Skies, Band History, Band Members, Musical Journey, Band Inspiration, Musical Style, Album Releases, Concert Tours, Fan Base, Music Creation Process, Songwriting Process, Band Achievements, Future Plans, Band Interviews, Press Coverage, Social Impact, Community Involvement, Collaborations, Music Awards.'
      },
      newsletter: {
          route: '/newsletter',
          pageName: `Newsletter ${campingInTheSkies}`,
          ogImage: shelterCover,
          description: 'Camping In The Skies: an alt rock band. Keep updated on their latest news, releases, and events. Subscribe to their newsletter and get exclusive access to their behind-the-scenes stories, sneak peeks, and special offers.',
          keywords: 'Newsletter, Camping In The Skies Updates, Band News, Latest Releases, Upcoming Events, Behind-The-Scenes Stories, Exclusive Content, Fan Engagement, Music Industry Insights, Band Interviews, Personal Anecdotes, Tour Announcements, Album Launches, Song Teasers, Fan Club News, Special Offers, Merchandise Updates, Meet and Greet Opportunities, Fan Art Features, Community Highlights,'
      },
      photos: {
          route: '/photos',
          pageName: `Photo Gallery ${campingInTheSkies}`,
          ogImage: shelterCover,
          description: 'Step into the vibrant world of Camping In The Skies through their Photo Gallery. This collection captures the band’s best moments, chronicling their journey from inception to the present day. From behind-the-scenes shots to concert highlights, experience the essence of Camping In The Skies.',
          keywords: 'Photo Gallery, Camping In The Skies, Best Moments, Band Journey, Behind-The-Scenes, Concert Highlights, Musical Universe, Living The Moment, Captured Memories, Band Photography, Musician’s Life, On-Stage Moments, Off-Stage Moments, Band’s Evolution, Musical Milestones, Creative Process, Passionate Performances, Inspirational Images, Empowering Visuals, Encouraging Journey,'
      },
      videos: {
          route: '/videos',
          pageName: `Video Gallery ${campingInTheSkies}`,
          ogImage: shelterCover,
          description: 'Camping In The Skies: an alt rock band. Showcasing their musical and visual talents in their video gallery. Explore their music videos, their captivating live performances, and their fun and creative behind-the-scenes videos.',
          keywords: 'Video Gallery, Camping In The Skies Videos, Band Performances, Music Videos, Behind-The-Scenes Footage, Concert Clips, Exclusive Previews, Song Teasers, Tour Diaries, Fan Reactions, Interview Footage, Studio Sessions, Album Launches, Meet and Greet Clips, Rehearsal Videos, Creative Process, Live Shows, Collaborations, Acoustic Sets'
      },
    };

export const songLinksList = {
    muse: {
        route: '/discography/muse',
        pageName: `Muse ${campingInTheSkies}`,
        ogImage: MuseArtwork,
        description: 'New single released december 1st 2023. Get inspired!',
        keywords: 'Single, Discography, Alt Rock Band, Self-Produced Music, Empowering Lyrics, Encouraging Music, Sleepless Nights, Long Shifts, Masterpieces, Musical Excellence, Polished Tracks, Soulful Music, Edinburgh and Tenerife, Online Collaboration, In-Person Collaboration, Original Music Videos, Original Photos, Camping in the Skies, Passionate Creations, Inspirational Tunes, Creative Journey, Independent Label'
    },
}
export const PhotoAlbumList = [
    {
        albumName: 'Bannerman\'s Bar. First gig',
        albumCover: BannermansBarApril2019AlbumCover,
        photos: BannermanApril19List,
        url: `${PagesList.photos.route}/live-at-bannermans-bar-2019`,
        description:
            'Camping in the skies\' first live performance. Edinburgh April 2019.\n' +
            ` ${mermbersAndPartners.mattSelvansFullName} - Vocals\n` +
            ` ${mermbersAndPartners.rodaidhFullName} - Guitar\n` +
            ` ${mermbersAndPartners.scottFullName} - Bass\n` +
            ` ${mermbersAndPartners.domFullName} - Drums\n` +
            `Photography - ${mermbersAndPartners.omarFullName}`,
        keywords: 'Rock Concert, Live Performance, Band Photography, Music Scene, Edinburgh Nightlife, UK Music, Indie Music, New Band, Music Event, Pub Gig, Camping In The Skies, Alt Rock Band, First Gig, Bannerman’s Bar, Edinburgh, UK, April 2019, Live Music, Concert Photography, Music Venue Pub'
    },
    {
        albumName: 'Live at Krakatoa May 2023',
        albumCover: KrakatoaMay2023AlbumCover,
        photos: KrakatoaMay23List,
        url: `${PagesList.photos.route}/live-at-krakatoa-may-2023`,
        description:
            'Live at Krakatoa supporting the Funky Red Pandas. Aberdeen May 2023 \n' +
            ` ${mermbersAndPartners.mattSelvansFullName} - Vocals\n` +
            ` ${mermbersAndPartners.rodaidhFullName} - Guitar\n` +
            ` ${mermbersAndPartners.bijayFullName} - Bass\n` +
            ` ${mermbersAndPartners.monniFullName} - Drums\n` +
            ` ${mermbersAndPartners.liamFullName} - Drums/Percussion.\n` +
            ` Photography - ${mermbersAndPartners.mattJollyFullName}`,
        keywords: 'Camping In The Skies, Second Gig, May 2023, Krakatoa Venue, Aberdeen, Live Music, Concert Photography, Music Venue, Alt Rock Band, UK Music Scene, Indie Music, Live Performance, Band Photography, Aberdeen Nightlife, Music Event, Matt Jolly Photography, The Funky Red Pandas, Full Fat, JwJ, Scotland Music'
    },
    { socialMedia: followOnInstagramCits, icon: socialMediaIcons.instagram},
]