import CampingintheSkiesKrakMay230065 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230065.jpg'
import CampingintheSkiesKrakMay230068 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230068.jpg'
import CampingintheSkiesKrakMay230071 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230071.jpg'
import CampingintheSkiesKrakMay230079 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230079.jpg'
import CampingintheSkiesKrakMay230082 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230082.jpg'
import CampingintheSkiesKrakMay230085 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230085.jpg'
import CampingintheSkiesKrakMay230093 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230093.jpg'
import CampingintheSkiesKrakMay230099 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230099.jpg'
import CampingintheSkiesKrakMay230102 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230102.jpg'
import CampingintheSkiesKrakMay230107 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230107.jpg'
import CampingintheSkiesKrakMay230108 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230108.jpg'
import CampingintheSkiesKrakMay230110 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230110.jpg'
import CampingintheSkiesKrakMay230114 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230114.jpg'
import CampingintheSkiesKrakMay230124 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230124.jpg'
import CampingintheSkiesKrakMay230126 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230126.jpg'
import CampingintheSkiesKrakMay230129 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230129.jpg'
import CampingintheSkiesKrakMay230138 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230138.jpg'
import CampingintheSkiesKrakMay230144 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230144.jpg'
import CampingintheSkiesKrakMay230148 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230148.jpg'
import CampingintheSkiesKrakMay230154 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230154.jpg'
import CampingintheSkiesKrakMay230167 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230167.jpg'
import CampingintheSkiesKrakMay230182 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230182.jpg'
import CampingintheSkiesKrakMay230187 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230187.jpg'
import CampingintheSkiesKrakMay230199 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230199.jpg'
import CampingintheSkiesKrakMay230200 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230200.jpg'
import CampingintheSkiesKrakMay230227 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230227.jpg'
import CampingintheSkiesKrakMay230231 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230231.jpg'
import CampingintheSkiesKrakMay230233 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230233.jpg'
import CampingintheSkiesKrakMay230234 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230234.jpg'
import CampingintheSkiesKrakMay230239 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230239.jpg'
import CampingintheSkiesKrakMay230243 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230243.jpg'
import CampingintheSkiesKrakMay230249 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230249.jpg'
import CampingintheSkiesKrakMay230255 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230255.jpg'
import CampingintheSkiesKrakMay230258 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230258.jpg'
import CampingintheSkiesKrakMay230259 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230259.jpg'
import CampingintheSkiesKrakMay230260 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230260.jpg'
import CampingintheSkiesKrakMay230261 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230261.jpg'
import CampingintheSkiesKrakMay230262 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230262.jpg'
import CampingintheSkiesKrakMay230266 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230266.jpg'
import CampingintheSkiesKrakMay230276 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230276.jpg'
import CampingintheSkiesKrakMay230287 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230287.jpg'
import CampingintheSkiesKrakMay230289 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230289.jpg'
import CampingintheSkiesKrakMay230314 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230314.jpg'
import CampingintheSkiesKrakMay230324 from '../krakatoa-may-2023/CampingintheSkiesKrakMay230324.jpg'
import KrakatoaMay2023AlbumCover from "../KrakatoaMay2023AlbumCover.jpg";

export const KrakatoaMay23List = [
    KrakatoaMay2023AlbumCover,
    CampingintheSkiesKrakMay230126,
    CampingintheSkiesKrakMay230065,
    CampingintheSkiesKrakMay230068,
    CampingintheSkiesKrakMay230071,
    CampingintheSkiesKrakMay230079,
    CampingintheSkiesKrakMay230082,
    CampingintheSkiesKrakMay230085,
    CampingintheSkiesKrakMay230093,
    CampingintheSkiesKrakMay230099,
    CampingintheSkiesKrakMay230102,
    CampingintheSkiesKrakMay230107,
    CampingintheSkiesKrakMay230108,
    CampingintheSkiesKrakMay230110,
    CampingintheSkiesKrakMay230114,
    CampingintheSkiesKrakMay230124,
    CampingintheSkiesKrakMay230129,
    CampingintheSkiesKrakMay230138,
    CampingintheSkiesKrakMay230144,
    CampingintheSkiesKrakMay230148,
    CampingintheSkiesKrakMay230154,
    CampingintheSkiesKrakMay230167,
    CampingintheSkiesKrakMay230182,
    CampingintheSkiesKrakMay230187,
    CampingintheSkiesKrakMay230199,
    CampingintheSkiesKrakMay230200,
    CampingintheSkiesKrakMay230227,
    CampingintheSkiesKrakMay230231,
    CampingintheSkiesKrakMay230233,
    CampingintheSkiesKrakMay230234,
    CampingintheSkiesKrakMay230239,
    CampingintheSkiesKrakMay230243,
    CampingintheSkiesKrakMay230249,
    CampingintheSkiesKrakMay230255,
    CampingintheSkiesKrakMay230258,
    CampingintheSkiesKrakMay230259,
    CampingintheSkiesKrakMay230260,
    CampingintheSkiesKrakMay230261,
    CampingintheSkiesKrakMay230262,
    CampingintheSkiesKrakMay230266,
    CampingintheSkiesKrakMay230276,
    CampingintheSkiesKrakMay230287,
    CampingintheSkiesKrakMay230289,
    CampingintheSkiesKrakMay230314,
    CampingintheSkiesKrakMay230324,
]