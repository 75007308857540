import * as React from "react";
import {useContext, useState, useEffect, useCallback, useRef, useMemo} from "react";
import {Box} from "@mui/system";
import {ThemeContext} from "../../contexts/ThemeContext";
import {LanguageContext} from "../../contexts/LocalizationContext/languages/LanguageContext";
import {LayoutContext} from "../../contexts/LayoutContext/LayoutContext";
import Grid from "@mui/material/Unstable_Grid2";
import {styled, Typography} from "@mui/material";
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import MuseArtwork from '../../media/songsArtwork/MuseArtwork.jpg'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {en} from "../../contexts/LocalizationContext/languages/en";
import Carousel from 'react-material-ui-carousel';
import {Link} from "react-router-dom";
import {Countdown} from "./Countdown";
import {PagesList} from "../../config";
import {OgMetaTagsPages} from "../ReusableComponents/OgMetaTags";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function songsIforTabProps(index) {
    return {
        id: `full-width-songs-info-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

function lyricsLanguageToViewProps(index) {
    return {
        id: `lyrics-language-to-view-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}


const StyledAppBar = styled(AppBar)(() => ({
    '&.MuiAppBar-root': {
        // position: 'fixed',
        backgroundColor: '#000000',
        color: '#fff'
    },
}));

const StyledTabs = styled(Tabs)(() => ({
    '&.MuiTabs-root': {
        // position: 'fixed',
        // backgroundColor: '#005cbe'
    },
}));

const StyledTab = styled(Tab)(() => ({
    '&.MuiTab-root': {
        // position: 'fixed',
        // backgroundColor: theme.palette.background.paper,
    },
}));

const StyledTabPanel = styled(Box)(() => ({
    '& .MuiBox-root': {
        padding: '0px 0px 0px 10px',
        border: '2px solid white',
        width: '100%',
        borderColor: '#fff',
    },
}));

export const Discography = ({ pageMeta }) => {
    const { isMobile, screenWidth, isTablet } = useContext(LayoutContext);
    const { language, dictionary } = useContext(LanguageContext)
    const { theme } = useContext(ThemeContext);

    const releases = {
        albumName: 'Album Name',
        albumCover: '',
        albumHistory: '',
        songs: [
            {songName: 'Camping In The Skies', image: 'https://i.scdn.co/image/ab67616d0000b273bd4939811586bf102c3add80', spotifyId: '745yomhNBdXLdRYTUsqIis', iframeColor: '#A04038', originalLyrics: en.citsLyrics, translatedLyrics: dictionary.citsLyrics, songCredits: dictionary.creditsRodScottBeedieMatt ,songHistory: ''},
            {songName: 'Moving On', image: 'https://i.scdn.co/image/ab67616d0000b273a601fa38dc0391f0c6a87847', spotifyId: '0EcSXSkPNQUeHho9u0fJA9', iframeColor: '#901800', originalLyrics: en.movingOnLyrics, translatedLyrics: dictionary.movingOnLyrics, songCredits: dictionary.creditsRodScottBeedieMatt ,songHistory: ''},
            {songName: 'Disobey', image: 'https://i.scdn.co/image/ab67616d0000b2731c30b5ac3ba85b68a763d54f', spotifyId: '0Yh0HdHypGJZiyKl97gof8', iframeColor: '#7F7F7F', originalLyrics: en.disobeyLyrics, translatedLyrics: dictionary.disobeyLyrics, songCredits: dictionary.creditsRodScottBeedieMatt ,songHistory: ''},
            {songName: 'Muse', image: MuseArtwork, spotifyId: '6cTvXYkR4yvpim4jVnx0eu', iframeColor: '#7F7F7F', songCredits: dictionary.creditsRodScottBeedieMatt, },
            // {comingSoon: 'Muse', releaseDate: '2023-12-01T00:00:00'},
        ]
    }

    const [ songToPlay, setSongToPlay ] = useState(releases.songs[0].spotifyId);
    const [ iframeColor, setIframeColor ] = useState(releases.songs[0].iframeColor);
    const [ songInfoToView, setSongInfoToView ] = React.useState(0);
    const [ lyricsLanguageToView, setLyricsLanguageToView ] = React.useState(0);
    const [ storyToDisplay, setStoryToDisplay ] = useState(releases.albumHistory)
    const [ originalLyricsToDisplay, setOriginalLyricsToDisplay ] = useState('')
    const [ translatedLyricsToDisplay, setTranslatedLyricsToDisplay ] = useState('')
    const [ songTitleToDisplay, setSongTitleToDisplay ] = useState(releases.songs[0].songName)

    const handleSongInfoToView = (event, newValue) => {
        setSongInfoToView(newValue);

    };const handleLyricsLanguage = (event, newValue) => {
        setLyricsLanguageToView(newValue);
    };

    const selectedSongHighlightColor = '#ffab00'

    const handleSelectedSong = useCallback(
        (spotifyId,songName, songOriginalLyrics, songTranslatedLyrics, iframeColor) => {
            setSongToPlay(spotifyId);
            setSongTitleToDisplay(songName)
            setOriginalLyricsToDisplay(songOriginalLyrics);
            setTranslatedLyricsToDisplay(songTranslatedLyrics);
            setIframeColor(iframeColor)
        },
        []
    );

    useEffect(() => {
        setStoryToDisplay(releases.albumHistory);
    }, [dictionary, releases.albumHistory]);

    useEffect(() => {
        if (songToPlay) {
            const selectedSong = releases.songs.find((song) => song.spotifyId === songToPlay);
            if (selectedSong) {
                setTranslatedLyricsToDisplay(selectedSong.translatedLyrics);
            }
        }
    }, [songToPlay, handleSelectedSong, dictionary, releases.songs]);


//enable horizontal scroll onMouseDown
    const [partnerInitialX, setPartnerInitialX] = useState(null);
    const boxRef = useRef(null);

    useEffect(() => {
        const handleMouseDown = (event) => setPartnerInitialX(event.clientX);
        const handleMouseMove = (event) => {
            if (event.buttons === 1 && partnerInitialX !== null) {
                const deltaX = event.clientX - partnerInitialX;
                const boxElement = boxRef.current;
                if (boxElement) {
                    boxElement.scrollLeft -= deltaX;
                    setPartnerInitialX(event.clientX);
                    event.preventDefault();
                }
            }
        };
        const handleMouseUp = (event) => {
            if (event.button === 0) {
                setPartnerInitialX(null);
            }
        };

        window.addEventListener('mousedown', handleMouseDown);
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);

        return () => {
            window.removeEventListener('mousedown', handleMouseDown);
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [partnerInitialX]);

    const songsGroups = [];
    for (let i = 0; i < releases.songs.length; i += 3) {
        songsGroups.push(releases.songs.slice(i, i + 3));
    }

    const responsiveCarouselSize = ( isMobile || isTablet ) ? screenWidth / 3 : 350

    return(
        <Grid container direction="row" justifyContent="center" alignItems="center" minHeight="100vh"  paddingTop={ theme.spacing(4) } xs={12}>
            <OgMetaTagsPages content={pageMeta} />
            <Grid display="flex" justifyContent="center" alignItems="center" flexDirection="column" textAlign="center" sm={12} lg={10} marginBottom={theme.spacing(3)}>
                <Typography variant="h3"> Singles released</Typography>
            </Grid>
            <Grid display="flex" justifyContent="center" alignItems="center" flexDirection="column" textAlign="center" width="100%" sm={12} lg={10} marginBottom={theme.spacing(3)}>
                <Box width={ ( !isMobile && !isTablet ) ? "calc(100% + 80px)" : "100%" } height={responsiveCarouselSize}>
                    <Carousel
                        id={"carousel"}
                        indicators={false}
                        enableSwipe={true}
                        autoPlay={false}
                        navButtonsAlwaysVisible={true}
                        navButtonsProps={{
                            style: {
                                backgroundColor: 'rgba(245,196,7,0.5)',
                                margin: 'auto',
                            },
                        }}
                    >
                        {songsGroups.map((group, groupIndex) => (
                            <Box
                                key={groupIndex}
                                width={ ( !isMobile && !isTablet ) ? "calc(100% - 80px)" : "100%" }
                                marginLeft={ isMobile || isTablet ? 0 : "40px" }
                                display="flex"
                                justifyContent={ group.length < 3 ? "space-evenly" : "space-between" }
                                flexDirection="row"
                            >
                                {group.map((song) => (
                                    song.comingSoon ? (
                                        <Box
                                            key={song.comingSoon}
                                            display="flex"
                                            flexDirection="column" // Change to column to center vertically
                                            alignItems="center" // Center horizontally
                                            justifyContent="center" // Center vertically
                                            width={responsiveCarouselSize}
                                            height={responsiveCarouselSize}
                                            onClick={() => handleSelectedSong(song.spotifyId, song.songName, song.originalLyrics, song.translatedLyrics, song.iframeColor)}
                                            sx={{
                                                border: songToPlay === song.spotifyId ? `4px solid ${selectedSongHighlightColor}` : 'none',
                                                overflow: 'hidden',
                                                backgroundColor: '#000000',
                                                color: '#fff',
                                                textAlign: 'center', // Center text horizontally within the Box
                                            }}
                                        >
                                            <Typography variant={isMobile ? "body1" : "h4"}>Coming soon...<br />{song.comingSoon}</Typography>
                                            { song.releaseDate && (
                                                <Countdown endDateAndTime={song.releaseDate} />
                                            )}
                                            <Link to={PagesList.newsletter.route} style={{ color: '#fff'}}>
                                                <Typography variant={isMobile ? "body1" : "h6"} >Get notified</Typography>
                                            </Link>
                                        </Box>
                                        ) : (
                                            <Box
                                            key={song.spotifyId}
                                            display="flex"
                                            flexDirection="row"
                                            width={responsiveCarouselSize}
                                            height={responsiveCarouselSize}
                                            onClick={() => handleSelectedSong(song.spotifyId, song.songName, song.originalLyrics, song.translatedLyrics, song.iframeColor)}
                                            sx={{
                                                border: songToPlay === song.spotifyId ? `4px solid ${selectedSongHighlightColor}` : 'none',
                                                overflow: 'hidden',
                                            }}
                                            >
                                                <img
                                                    width="100%"
                                                    height="100%"
                                                    src={song.image}
                                                    alt={song.songName}
                                                    draggable="false"
                                                />
                                            </Box>
                                        )
                                ))}
                            </Box>
                        ))}
                    </Carousel>
                </Box>
            </Grid>
            <Grid bgcolor={'#2a2a2a'} width="100%" lg={10} xs={12}>
                <iframe
                    title={`${songToPlay}`}
                    width="100%"
                    height="100"
                    // style="border-radius:12px"
                    src={`https://open.spotify.com/embed/album/${songToPlay}?utm_source=generator&theme=0`}
                    frameBorder="0"
                    allowFullScreen=""
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                    loading="lazy">
                </iframe>
            </Grid>
            <Grid item lg={10} sm={12} sx={{ height: '100%', width: '100%', overflow: 'auto' }}>
                <Box>
                    <StyledAppBar position="sticky">
                        <Tabs
                            value={songInfoToView}
                            onChange={handleSongInfoToView}
                            // indicatorColor="secondary"
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="full width tabs example"
                            sx={{
                                '& .MuiTabs-indicator': {
                                    border: `2px solid ${selectedSongHighlightColor}`,
                                },
                            }}
                        >
                            <Tab label={dictionary.lyrics} {...songsIforTabProps(0)} />
                            <Tab label={"About"} {...songsIforTabProps(1)} sx={{display: 'none'}} />
                            <Tab label={dictionary.credits} {...songsIforTabProps(2)} />
                        </Tabs>
                    </StyledAppBar>
                    { songInfoToView === 0 && (
                        <StyledTabPanel value={songInfoToView} index={0} dir={theme.direction}>
                            <StyledTabs
                                value={lyricsLanguageToView}
                                onChange={handleLyricsLanguage}
                                textColor="inherit"
                                aria-label="tabs example"
                                sx={{p: 0, m: 0, '& .MuiTabs-indicator': {border: `2px solid ${selectedSongHighlightColor}`,},}}
                            >
                                { language !== 'en' && (
                                    <StyledTab theme={theme} label={dictionary.translatedToLanguageContext} {...lyricsLanguageToViewProps(0)}/>
                                )}
                                <StyledTab theme={theme} label={dictionary.originalLyrics} {...lyricsLanguageToViewProps(1)}/>
                            </StyledTabs>
                            <TabPanel value={lyricsLanguageToView} index={0} dir={theme.direction} >
                                <Typography textAlign="center" variant="h5" sx={{marginTop: theme.spacing(3)}}>{songTitleToDisplay}</Typography>
                                <Typography textAlign="center" sx={{ whiteSpace: 'pre-line', lineHeight: 1.1 , marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}>{translatedLyricsToDisplay}</Typography>
                            </TabPanel>
                            <TabPanel value={lyricsLanguageToView} index={1} dir={theme.direction} >
                                <Typography textAlign="center" variant="h5" sx={{marginTop: theme.spacing(3)}}>{songTitleToDisplay}</Typography>
                                <Typography textAlign="center" sx={{ whiteSpace: 'pre-line', lineHeight: 1.1 , marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}>{originalLyricsToDisplay}</Typography>
                            </TabPanel>
                        </StyledTabPanel>
                    )}
                    <TabPanel value={songInfoToView} index={1} dir={theme.direction} style={{ minHeight: '70vh' }}>
                        <Typography textAlign="center" sx={{ whiteSpace: 'pre-line', lineHeight: 1.1 , marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}>{storyToDisplay}</Typography>
                    </TabPanel>
                    <TabPanel value={songInfoToView} index={2} dir={theme.direction} style={{ minHeight: '70vh' }}>
                        <Typography textAlign="center" variant="h5" sx={{marginTop: theme.spacing(3)}}>Credits</Typography>
                        <Typography textAlign="center" sx={{ whiteSpace: 'pre-line', lineHeight: 1.1 , marginTop: theme.spacing(3), marginBottom: theme.spacing(3) }}>{releases.songs[songInfoToView].songCredits}</Typography>
                    </TabPanel>
                </Box>
            </Grid>
        </Grid>

    )
}