import React, { useContext, useEffect, useRef } from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import { MainVideoPlayer } from './MainVideoPlayer';
import { ThemeContext } from '../contexts/ThemeContext';
import { LanguageContext } from "../contexts/LocalizationContext/languages/LanguageContext";
import {Button, styled, Typography} from "@mui/material";
import { LayoutContext } from "../contexts/LayoutContext/LayoutContext";
import { BackgroundVideo } from "./BackgroundVideo";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import shelterCover from '../media/pictures/cits-shelter-cover.jpg';
import {Box} from "@mui/system";
import {socialMediaIcons} from "../displayConfig";
import {SpotifyIcon} from "../media/platform-icons/music-platforms/SpotifyIcon";
import paypalIcon from '../media/paypal_icon.png'
import HomebarGigSquare from '../media/pictures/gigs/Homebar-Gig-Square.jpg'
import {
    followOnFacebookCits,
    followOnInstagramCits,
    followOnTikTokCits,
    followOnTwitterCits,
    followOnYouTubeCits,
    PagesList,
    paypalDonateLink,
    spotifyCits,
} from "../config";
import {OgMetaTagsPages} from "./ReusableComponents/OgMetaTags";
import {CountdownToEvent} from "./CountdownToEvent/CountdownToEvent";
import PhotoGalleryBackground from "../media/pictures/gigs/PhotoGalleryBackground.jpg";
import {GigEvent} from "./GigEvent/GigEvent";

const MyButton = styled(Button)(({ theme }) => ({
    width: '25%',
    border: '2px solid white',
    borderColor: '#fff',
    [theme.breakpoints.up("md")]: {
        fontSize: 20
    },
    [theme.breakpoints.down("md")]: {
        width: '45%',
        opacity: 0.6,
    },
    [theme.breakpoints.down("sm")]: {
        width: '48%',
        opacity: 0.6,
        fontSize: 13,
    },
}));

const iconSize = 50;

const DonatePaypalButton = () => {
    const { isMobile } = useContext(LayoutContext);

    return (
        <Button
            variant="outlined"
            size="small"
            onClick={() => window.open(paypalDonateLink)}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#1f1f1f',
                color: '#fff',
                fontWeight: 'bolder',
                border: 'none',
                height: iconSize,
                width: iconSize,
                minWidth: iconSize,
                padding: 0,
                fontSize: 7,
                '& img': {
                    filter: 'grayscale(100%) invert(100%)',
                },
                // '&:hover img': {
                //     filter: 'grayscale(100%)',
                // },
                '&:hover': {
                    backgroundColor: '#1f1f1f',
                    color: '#fff',
                    border: 'none',
                },
            }}
        >
            <img src={paypalIcon} alt="PayPal" style={{ width: 25, height: 25 }} />
            Donate
        </Button>
    );
}

export const SocialMediaIconsRow = () => {
    const { isMobile } = useContext(LayoutContext);

    const socialMedia = [
        { icon: <SpotifyIcon size={iconSize} />, link: spotifyCits },
        { icon: socialMediaIcons.facebook, link: followOnFacebookCits },
        { icon: socialMediaIcons.instagram, link: followOnInstagramCits },
        { icon: socialMediaIcons.youTube, link: followOnYouTubeCits },
        { icon: socialMediaIcons.twitter, link: followOnTwitterCits },
        { icon: socialMediaIcons.tikTok, link: followOnTikTokCits },
        { component: <DonatePaypalButton /> },
    ];

    return (
        <Box
            sx={{
                width: isMobile ? '100%' : '50%',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 1
            }}
            zIndex={2}
        >
            {socialMedia.map((item, index) => (
                item.component ? (
                    <div key={index}>{item.component}</div>
                ) : (
                    <a href={item.link} target="_blank" rel="noopener noreferrer" key={index}>
                        {React.cloneElement(item.icon, { size: iconSize })}
                    </a>
                )
            ))}
        </Box>
    );
}

export const Homepage = ({ pageMeta }) => {
    const { theme } = useContext(ThemeContext);
    const { isMobile } = useContext(LayoutContext);
    const { dictionary } = useContext(LanguageContext);
    const location = useLocation();
    const navigate = useNavigate();
    const observerRef = useRef();


    useEffect(() => {
        const elementId = location.hash.substring(1); // Remove the leading '#' from the hash
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const callback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    navigate(`#${entry.target.id}`, { replace: true });
                }
            });
        };

        observerRef.current = new IntersectionObserver(callback, options);

        const targets = document.querySelectorAll(
            '#album, #stories, #bandcamp-album-everything-starts, #watch-and-feel',
        );
        targets.forEach((target) => observerRef.current.observe(target));

        return () => {
            observerRef.current.disconnect();
        };
    }, [navigate]);

    return (
        <Box>
            <OgMetaTagsPages content={pageMeta} />
            {/*<GigEvent />*/}
            <Box
                height={isMobile ? "50vh" : "100vh"}
                width="100vw"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                    backgroundImage: `url(${shelterCover})`,
                    backgroundSize: isMobile ? 'cover' : 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }}
            >
                <SocialMediaIconsRow />
            </Box>

            <Box sx={{width: '100%', height: '35vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Typography textAlign="center" variant="h5">
                    Alt rock band where land is no limit. currently recording their next release
                    <Link to={PagesList.aboutUs.route}> read more...</Link>
                </Typography>
            </Box>
        </Box>
    );
};