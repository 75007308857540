import A2IMG20190401203350 from '../Bannermans-april-2019/A2IMG20190401203350.jpg'
import BICE2198 from '../Bannermans-april-2019/BICE2198.jpg'
import BICE2212 from '../Bannermans-april-2019/BICE2212.jpg'
import BICE2218 from '../Bannermans-april-2019/BICE2218.jpg'
import BICE2220 from '../Bannermans-april-2019/BICE2220.jpg'
import BICE2237 from '../Bannermans-april-2019/BICE2237.jpg'
import BICE2267 from '../Bannermans-april-2019/BICE2267.jpg'
import BICE2271 from '../Bannermans-april-2019/BICE2271.jpg'
import BICE2274 from '../Bannermans-april-2019/BICE2274.jpg'
import BICE2279 from '../Bannermans-april-2019/BICE2279.jpg'
import BICE2281 from '../Bannermans-april-2019/BICE2281.jpg'
import BICE2289 from '../Bannermans-april-2019/BICE2289.jpg'
import BICE2302 from '../Bannermans-april-2019/BICE2302.jpg'
import BICE2314 from '../Bannermans-april-2019/BICE2314.jpg'
import BICE2320 from '../Bannermans-april-2019/BICE2320.jpg'
import BICE2333 from '../Bannermans-april-2019/BICE2333.jpg'
import BICE2348 from '../Bannermans-april-2019/BICE2348.jpg'
import BICE2352 from '../Bannermans-april-2019/BICE2352.jpg'
import BICE2353 from '../Bannermans-april-2019/BICE2353.jpg'
import BICE2372 from '../Bannermans-april-2019/BICE2372.jpg'
import BICE2378 from '../Bannermans-april-2019/BICE2378.jpg'
import image001 from '../Bannermans-april-2019/image001.jpg'
import image002 from '../Bannermans-april-2019/image002.jpg'
import image003 from '../Bannermans-april-2019/image003.jpg'
import image005 from '../Bannermans-april-2019/image005.jpg'
import image007 from '../Bannermans-april-2019/image007.jpg'
import image008 from '../Bannermans-april-2019/image008.jpg'
import image009 from '../Bannermans-april-2019/image009.jpg'
import image010 from '../Bannermans-april-2019/image010.jpg'
import image011 from '../Bannermans-april-2019/image011.jpg'
import image012 from '../Bannermans-april-2019/image012.jpg'
import image013 from '../Bannermans-april-2019/image013.jpg'
import image014 from '../Bannermans-april-2019/image014.jpg'
import IMG20190405215640 from '../Bannermans-april-2019/IMG20190405215640.jpg'
import IMG20190405221449 from '../Bannermans-april-2019/IMG20190405221449.jpg'
import IMG20190406232600 from '../Bannermans-april-2019/IMG20190406232600.jpg'
import IMG20190412163349 from '../Bannermans-april-2019/IMG20190412163349.jpg'
import IMG20190412163703 from '../Bannermans-april-2019/IMG20190412163703.jpg'

export const BannermanApril19List = [
    BICE2378,
    A2IMG20190401203350,
    BICE2198,
    BICE2212,
    BICE2218,
    BICE2220,
    BICE2237,
    BICE2267,
    BICE2271,
    BICE2274,
    BICE2279,
    BICE2281,
    BICE2289,
    BICE2302,
    BICE2314,
    BICE2320,
    BICE2333,
    BICE2348,
    BICE2352,
    BICE2353,
    BICE2372,
    image001,
    image002,
    image003,
    image005,
    image007,
    image008,
    image009,
    image010,
    image011,
    image012,
    image013,
    image014,
    IMG20190405215640,
    IMG20190405221449,
    IMG20190406232600,
    IMG20190412163349,
    IMG20190412163703,
]