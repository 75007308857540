import React from 'react';
import { hydrate, render } from 'react-dom';
import App from './App';
import {ThemeContextProvider} from "./contexts/ThemeContext";

// Wrap your App component with ThemeContextProvider
const AppWithTheme = () => (
    <ThemeContextProvider>
        <App />
    </ThemeContextProvider>
);

const rootElement = document.getElementById('root');

// Use hydrate if rootElement already has child nodes, otherwise use render
if (rootElement.hasChildNodes()) {
    hydrate(
        <React.StrictMode>
            <AppWithTheme />
        </React.StrictMode>,
        rootElement
    );
} else {
    render(
        <React.StrictMode>
            <AppWithTheme />
        </React.StrictMode>,
        rootElement
    );
}
