import React, { useContext, useState, useEffect } from "react";
import { Box, Grid, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PhotoGalleryBackground from '../../src/media/pictures/gigs/PhotoGalleryBackground.jpg';
import { LayoutContext } from "../contexts/LayoutContext/LayoutContext";
import { MainVideoPlayerVideoData } from "../displayConfig";
import {OgMetaTagsPages} from "./ReusableComponents/OgMetaTags";

export const VideoGallery = ({ pageMeta }) => {
    const { isMobile, isTablet, cardsPadding } = useContext(LayoutContext);
    const videos = MainVideoPlayerVideoData();

    const [showFloatingWindow, setShowFloatingWindow] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);

    const openFloatingWindowAndPlayVideo = (playlistId) => {
        setSelectedVideo(playlistId);
        setShowFloatingWindow(true);
        document.getElementById('video-iframe').src += '&autoplay=1'
    };

    const handleCloseFloatingWindow = () => {
        setSelectedVideo(null);
        setShowFloatingWindow(false);
    };

    useEffect(() => {
        const closeOnOutsideClick = (event) => {
            if (showFloatingWindow && selectedVideo) {
                const iframe = document.querySelector("#video-iframe");
                if (iframe && !iframe.contains(event.target)) {
                    handleCloseFloatingWindow();
                }
            }
        };

        const closeOnEscKey = (event) => {
            if (showFloatingWindow && selectedVideo && event.key === "Escape") {
                handleCloseFloatingWindow();
            }
        };

        if (showFloatingWindow && selectedVideo) {
            document.addEventListener("mousedown", closeOnOutsideClick);
            document.addEventListener("keydown", closeOnEscKey);
        }

        return () => {
            document.removeEventListener("mousedown", closeOnOutsideClick);
            document.removeEventListener("keydown", closeOnEscKey);
        };
    }, [showFloatingWindow, selectedVideo]);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
                width: '100%',
                paddingBottom: isMobile ? 10 : 20,
                paddingTop: isMobile ? 7 : 17,
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${PhotoGalleryBackground})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
            }}
        >
            <OgMetaTagsPages content={pageMeta} />
            <Grid item large={11} xs={10} sx={{ textAlign: 'center' }}>
                <Typography variant="h3" sx={{ color: '#fff' }}>Videos</Typography>
                <Typography variant="h5" sx={{ color: '#fff' }}>Browse Camping In The Skies Videos</Typography>
            </Grid>
            <Grid item
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
            >
                {videos.map((video, i) => (
                    video.socialMedia ? (
                        <Grid
                            item key={i}
                            sx={{ padding: cardsPadding, cursor: 'pointer' }}
                            onClick={() => window.open(video.socialMedia, '_blank')}
                        >
                            <Box
                                height={ isMobile ? "60vw" : 225}
                                width={ isMobile ? "90vw" : 400}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column', // Center content vertically
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: '#1f1f1f',
                                    ':hover': {
                                        backgroundColor: '#262626', // Change to a brighter color on hover
                                    },
                                }}
                            >
                                <Typography variant="h5" sx={{ color: '#fff', position: 'absolute', backgroundColor: 'rgba(0,0,0,0.7)', padding: '0px 5px', borderRadius: 2 }}>Follow us on YouTube</Typography>
                                {React.cloneElement(video.icon, {size: 150, firstColor: '#1f1f1f', secondColor: '#ffffff'})}
                            </Box>
                        </Grid>
                    ) : (
                        <Grid item key={i} sx={{ padding: cardsPadding }}>
                            <Box
                                height={isMobile ? "50.56vw" : 225}
                                width={isMobile ? "90vw" : 400}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${video.ytThumbnail})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    ':hover': {
                                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(${video.ytThumbnail})`,
                                    },
                                }}
                                onClick={() => openFloatingWindowAndPlayVideo(video.videoId)}
                            >
                                { video.isNameOnThumbnail === false && (
                                    <Typography variant="h5" textAlign="center" sx={{ color: '#fff', padding: 2 }}>{video.name}</Typography>
                                )}
                            </Box>
                        </Grid>
                    )
                ))}
            </Grid>
            {showFloatingWindow && selectedVideo && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999,
                    }}
                >
                    <IconButton
                        sx={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            color: '#fff',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        }}
                        onClick={handleCloseFloatingWindow}
                    >
                        <CloseIcon />
                    </IconButton>
                    <iframe
                        id="video-iframe"
                        width={ isMobile ? "100%" : isTablet ? "80%" : "60%"}
                        height={ isMobile ? "50%" : isTablet ? "80%" : "60%"}
                        src={`https://www.youtube.com/embed/${selectedVideo}?autoplay=true&amp;showinfo=false&amp;rel=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fdreamtheater.net&amp;widgetid=13`}
                        title="Video"
                        style={{ border: 'none' }}
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                    />
                </Box>
            )}
        </Grid>
    )
}
