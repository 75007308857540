import React, {createContext, useState} from 'react';
import {en} from './en'
import {es} from "./es";
import {it} from "./it";

export const dictionaries = {
    en,
    es,
    it,
};

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    //TODO find a way to add this multilingual to the SEO
    const defaultLanguage = navigator.language.substring(0, 2);
    const [languageState, setLanguageState] = useState({
        language: defaultLanguage,
        dictionary: dictionaries[defaultLanguage],
    });

    const setLanguage = (language) => {
        if (dictionaries[language]) {
            setLanguageState({ language, dictionary: dictionaries[language] });
        }
    };

    return (
        <LanguageContext.Provider value={{ ...languageState, setLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};
