import React, {useState, useEffect, useCallback, useContext} from 'react';
import {Typography} from "@mui/material";
import {LayoutContext} from "../../contexts/LayoutContext/LayoutContext";

export const Countdown = ({endDateAndTime}) => {

    const { isMobile } = useContext(LayoutContext);
    const getTimeRemaining = useCallback(() => {
        // 2023-12-01T00:00:00
        const releaseDate = new Date(`${endDateAndTime}Z`);
        const currentDate = new Date();

        const totalSeconds = Math.max(Math.floor((releaseDate - currentDate) / 1000), 0);

        const days = Math.floor(totalSeconds / (60 * 60 * 24));
        const hours = Math.floor((totalSeconds % (60 * 60 * 24)) / (60 * 60));
        const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
        const seconds = totalSeconds % 60;

        return {
            days: String(days).padStart(2, '0'),
            hours: String(hours).padStart(2, '0'),
            minutes: String(minutes).padStart(2, '0'),
            seconds: String(seconds).padStart(2, '0'),
        };
    }, [endDateAndTime]);

    const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining());

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeRemaining(getTimeRemaining());
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [getTimeRemaining]);

    return (
        <div>
            <Typography variant={isMobile ? "body1" : "h5"}>{`${timeRemaining.days} D - ${timeRemaining.hours} H - ${timeRemaining.minutes} M - ${timeRemaining.seconds} S`}</Typography>
        </div>
    );
};
