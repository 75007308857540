import React, {useContext, useMemo} from "react";

import {
    FacebookTwoColorIcon,
    InstagramTwoColorIcon,
    SoundCloudTwoColorIcon,
    TikTokTwoColorIcon,
    TwitterTwoColorIcon,
    WebsiteTwoColorIcon,
    YouTubeTwoColorIcon
} from "./media/TwoColorSocialMediaIcons";
import {CampingInTheSkiesLogo} from "./media/logo/CampingInTheSkiesLogo";
import {LanguageContext} from "./contexts/LocalizationContext/languages/LanguageContext";
import {
    citsBandcamp,
    amazonMusicCits,
    appleMusicCits,
    followOnFacebookCits,
    followOnInstagramCits,
    followOnYouTubeCits,
    homepage,
    citsWebsite,
    shareFacebookCits,
    shareOnWhatsAppThe,
    shareTwitterCits,
    spotifyCits,
    youTubeMusicCits
} from "./config";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import {BandCampIcon} from "./media/platform-icons/music-platforms/BandCampIcon";
import {YouTubeMusicIcon} from "./media/platform-icons/music-platforms/YouTubeMusicIcon";
import {AmazonMusicIcon} from "./media/platform-icons/music-platforms/AmazonMusicIcon";
import {AppleMusicIcon} from "./media/platform-icons/music-platforms/AppleMusicIcon";
import {SpotifyIcon} from "./media/platform-icons/music-platforms/SpotifyIcon";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import {WhatsAppIcon} from "./media/platform-icons/social-media-platforms/WhatsAppIcon";
import {FacebookIcon} from "./media/platform-icons/social-media-platforms/FacebookIcon";
import {InstagramIcon} from "./media/platform-icons/social-media-platforms/InstagramIcon";
import {YouTubeIcon} from "./media/platform-icons/social-media-platforms/YouTubeIcon";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {TwitterIcon} from "./media/platform-icons/social-media-platforms/TwitterIcon";

export const socialMediaIcons = {
    youTube: <YouTubeTwoColorIcon/>,
    facebook: <FacebookTwoColorIcon/>,
    instagram: <InstagramTwoColorIcon/>,
    twitter: <TwitterTwoColorIcon/>,
    tikTok: <TikTokTwoColorIcon/>,
    website: <WebsiteTwoColorIcon/>,
    soundCloud: <SoundCloudTwoColorIcon/>,
}
export const fullMembersDataArray = [
    {
        imageSrc: "https://campingintheskies.com/wp-content/uploads/2022/09/RodyBlueSmall.png",
        name: "Rodaidh Mackay",
        job: "Guitarist, Singer, Producer",
        story: "Hailing from the misty highlands of Scotland, Rodaidh's musical journey began at the tender age of 8 with the haunting melodies of traditional bagpipe music. As he grew older, his passion for music led him to the electric guitar and he has been playing in bands for over 16 years. Influenced by legends like Alice Cooper, Zak Wylde, and Dream Theater, Rodaidh's talent knows no bounds. When he's not performing, he works as a producer and music educator in Edinburgh.",
        socialLinks: [
            {icon: socialMediaIcons.facebook, link: "https://www.facebook.com/rodaidhmackay.musician"},
            {icon: socialMediaIcons.instagram, link: "https://www.instagram.com/rodaidhmackay/"},
            {icon: socialMediaIcons.youTube, link: "https://www.youtube.com/rodaidhmackay"},
            {icon: socialMediaIcons.twitter, link: "https://twitter.com/rodaidhmackay"},
            {icon: socialMediaIcons.tikTok, link: "https://www.tiktok.com/@rodaidhmackay"}
        ]
    },
    {
        imageSrc: "https://campingintheskies.com/wp-content/uploads/2022/09/MattBlueSmall.png",
        name: "Matt Selvans",
        job: "Songwriter, Singer",
        story: "A vocalist/songriter with very confusing origins, a real side-effect of globalization. In his early 20's, started to make a living out of busking which he still does to this day. He tried selling his soul to the devil but he didn't want it even when he offered it for free. Despite dabbling in various genres, from country to classical music, rock has always held a special place in his heart. Influenced by legends like System of a Down, Led Zeppelin,  Queen and the Roman empire, Matt's passion for music never wanes.",
        socialLinks: [
            {icon: socialMediaIcons.website, link: "https://mattselvans.com/"},
        ]
    }
]

export const partnersDataArray = [
    {
        imageSrc: "https://campingintheskies.com/wp-content/uploads/2023/04/OmarStandard.jpg",
        name: "Omar",
        job: "Photographer",
        story: "Omar, the son of Cuzco and nephew of Kronk, has been our cherished photographer since April 2019. With his keen eye and artistic vision, he has captured our best moments and ideas, preserving them forever in stunning images and videos. His talent behind the lens has allowed us to relive those memories time and time again.",
        socialLinks: [
            {icon: socialMediaIcons.instagram, link: "https://www.instagram.com/omar_ddok/"},
            {icon: socialMediaIcons.facebook, link: "https://www.facebook.com/odocherty/about"},
        ]
    },
    {
        imageSrc: "https://campingintheskies.com/wp-content/uploads/2023/05/BjStandard1.jpg",
        name: "Bijay Shrestha",
        job: "Bass player",
        story: "Bijay from Kathmandu, Nepal played bass since his early teens. His brother Bikrum played drums. Bijay tattooed and studied Nepali art. He worked as a session musician with various bands: Tamishira, X-Mantra, Ugrakarma, Monkey Temple, Urban Gypsy, Karma Band. Touring India, China, Europe, Bijay ran tattoo studios and a restaurant. Moved to Scotland, tattoos professionally in Edinburgh and plays bass for Camping in the Skies. Bijay's influences: Bikrum Shrestha, Victor Wooten, Richard Bona, Billy Sheehan, Dimebag Darrel, Max Calvalera.",
        socialLinks: [
            {icon: socialMediaIcons.instagram, link: "https://www.instagram.com/bjbassnepal/"},
            {icon: socialMediaIcons.instagram, link: "https://www.instagram.com/tatt_art_nepal/"}
        ]
    },
    {
        imageSrc: "https://campingintheskies.com/wp-content/uploads/2023/05/MonniStandard2.jpg",
        name: "Monni S. Kamenov",
        job: "Drummer",
        story: "Monni is a drummer from Bulgaria, a land of ancient heroes and legends. He met Rodaidh in the misty highlands of Scotland, where they forged a musical bond by sacrificing a guitar and a drum kit to the gods. He dwells in the rehearsing rooms around Edinburgh, always ready for action and adventure.",
        socialLinks: [
            {icon: socialMediaIcons.facebook, link: "https://www.facebook.com/monni.s.kamenov"},
            {icon: socialMediaIcons.instagram, link: "https://www.instagram.com/monni_bg/"},
            {icon: socialMediaIcons.youTube, link: "https://www.youtube.com/channel/UCXZCuwwto3CMRl9uqvGlRPg"},
            {icon: socialMediaIcons.soundCloud, link: "https://soundcloud.com/simeon-kamenov"}
        ]
    },
    {
        imageSrc: "https://campingintheskies.com/wp-content/uploads/2023/05/BeedieStandard2.jpg",
        name: "Liam Beedie",
        job: "Drummer",
        story: "Liam and Rodaidh had a successful band together before, but destiny threw a curveball at them. They decided to overcome the challenge and create Camping In The Skies along with Scott and Matt. Liam is the son of Thor, but he prefers drum sticks to a hammer. Being the son of a god, he has a captivating rhythm on the drums that enchants everyone who listens to him. He is also a devoted father of two, who are inheriting his dark magic.",
        socialLinks: [
            {icon: socialMediaIcons.instagram, link: "https://www.instagram.com/beedieboy7/"},
            {icon: socialMediaIcons.facebook, link: "https://www.facebook.com/beedieboy"}
        ]
    },
    {
        imageSrc: "https://campingintheskies.com/wp-content/uploads/2023/04/StuStandard.jpg",
        name: "Stuart Airzee",
        job: "Bass player",
        story: "Stu hails from the north east of Scotland, land of rugged beauty and wild spirit. He fell in love with the bass at 14 and has played in many bands throughout the years, spreading his groove and passion. He's currently the bass player for The Funky Red Pandas, a band that blends funk, soul, and rock with a touch of Scottish flair. His influences include: RHCP, Jamiroquai, Vulfpeck, Chic and anything funky! \"I've known Rodaidh for a good few, he's a great friend and musician. When he asked me if I wanted to work on some tracks with CITS, I was thrilled and eager to get involved.\"",
        socialLinks: [
            {icon: socialMediaIcons.youTube, link: "https://youtube.com/@stuartairzee4618"},
            {icon: socialMediaIcons.website, link: "https://thefunkyredpandas.band/"}
        ]
    },
    {
        imageSrc: "https://campingintheskies.com/wp-content/uploads/2023/04/Samuel-Bradley-standard.jpg",
        name: "Samuel M Bradley",
        job: "Bass player",
        story: "Samuel Bradley is a bass guitarist and double bassist who can bring any song to life with his versatile and expressive playing. He is available for sessions and remote recording, as well as composing and arranging original music. He also offers friendly bass tuition in Glasgow's Southside, where he shares his passion and expertise with aspiring musicians. Samuel Bradley is not just a bass player, he is a musical storyteller.",
        socialLinks: [
            {icon: socialMediaIcons.facebook, link: "https://www.facebook.com/samuel.bradley.9674"},
            {icon: socialMediaIcons.website, link: "https://samuelmbradley.com/"}
        ]
    },
    {
        imageSrc: "https://campingintheskies.com/wp-content/uploads/2022/09/ScottBLueTrim.png",
        name: "Scott from Scotland",
        job: "Bass player",
        story: "Scott, also known as \"phylum Porifera\" or \"Sponge\" for the barbarians, was a core member of Camping In The Skies. He had a strong symbiosis with Beedie, the son of Thor. Rodaidh and him crafted a beautiful blend of soundwaves that Matt couldn't help but sing along. He now became a Mormon and he's spreading God's word in Siberia. Jokes aside, he suddenly felt a divine inspiration to create songs in a new genre worthy of the gods.",
        socialLinks: []
    },
    {
        imageSrc: "https://campingintheskies.com/wp-content/uploads/2022/09/dOMbLUE.png",
        name: "Dom Owtram",
        job: "Drummer",
        story: "Dom met Rodaidh during a fateful audition for a music project at uni. Although unable to fully commit to the band at the time, their connection was undeniable. Dom has since stepped in as the heartbeat of CITS, live and remotely recording at various points since the group's inception. Drummer and producer for Gloucester Ln. Dom's influences include legendary drummers: Chad Smith(RHCP), Mike Portnoy(DT), Gavin Harrison(Porcupine Tree), Adam Deitch(Lettuce), Ian Paice(Deep Purple), Tre Cool(Green Day) and Travis Barker.",
        socialLinks: [{icon: socialMediaIcons.website, link: "https://linktr.ee/gloucesterln"},]
    },
]

export const MainVideoPlayerVideoData = () => {
    return useMemo(() => (
        [
            {
                name: 'Muse (Official Music Video)',
                videoId: '-VL-u-c1jCE',
                playlistId: 'https://www.youtube.com/watch?v=-VL-u-c1jCE&list=PLWVpjoVPTvc6C5hJbvdIehMzc8tgaXSUy&index=1',
                start: 331,
                end: 345,
                ytThumbnail: 'https://i.ytimg.com/vi/-VL-u-c1jCE/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBLYf_1w0SeJtcMELXvLKBpOO2jHA',
                logo: <CampingInTheSkiesLogo />
            },
            {
                name: 'Muse Live at Krakatoa 2023',
                isNameOnThumbnail: false,
                videoId: 'lDT6-mMOyvQ',
                playlistId: 'https://www.youtube.com/watch?v=lDT6-mMOyvQ&list=PLWVpjoVPTvc6C5hJbvdIehMzc8tgaXSUy&index=7',
                start: 331,
                end: 345,
                ytThumbnail: 'https://i.ytimg.com/vi/lDT6-mMOyvQ/hq720.jpg?sqp=-oaymwE2CNAFEJQDSFXyq4qpAygIARUAAIhCGAFwAcABBvABAfgB_gmAAtAFigIMCAAQARh_IBQoNTAP&rs=AOn4CLAf6qjLUviCVX6cQWMMzgAp8Kwapg',
                logo: <CampingInTheSkiesLogo />
            },
            {
                name: 'Disobey Live at Krakatoa May 2023',
                videoId: 'DDhCQ4VLOEM',
                playlistId: 'https://www.youtube.com/embed/DDhCQ4VLOEM?si=XTzpaesBMJGcfOTy',
                start: 331,
                end: 345,
                ytThumbnail: 'https://i.ytimg.com/vi/DDhCQ4VLOEM/maxresdefault.jpg',
                logo: <CampingInTheSkiesLogo />
            },
            {
                name: 'Disobey Official Music Video',
                videoId: 'FTlNNAjyDr0',
                playlistId: 'https://www.youtube.com/embed/FTlNNAjyDr0?si=8ATSCbAWYlwih6CS',
                start: 331,
                end: 345,
                ytThumbnail: 'https://i.ytimg.com/vi/FTlNNAjyDr0/maxresdefault.jpg',
                logo: <CampingInTheSkiesLogo />
            },
            {
                name: 'Camping In The Skies Official Music Video',
                videoId: 'v5QQHKf1L58',
                playlistId: 'https://www.youtube.com/embed/v5QQHKf1L58?si=cVqCB6i7Z1pliXYo',
                start: 331,
                end: 345,
                ytThumbnail: 'https://i.ytimg.com/vi/v5QQHKf1L58/maxresdefault.jpg',
                logo: <CampingInTheSkiesLogo />
            },
            {
                name: 'Moving On Official Music Video',
                videoId: '-L40ZD2JVxc',
                playlistId: 'https://www.youtube.com/embed/-L40ZD2JVxc?si=qKLdeM1mSYqLmXkM',
                start: 134,
                end: 145,
                ytThumbnail: 'https://i.ytimg.com/vi_webp/-L40ZD2JVxc/maxresdefault.webp',
                logo: <CampingInTheSkiesLogo />
            },
            {
                name: 'Camping In The Skies Live Acoustic Video',
                videoId: 'BSmIi6AptEw',
                playlistId: 'https://www.youtube.com/embed/BSmIi6AptEw?si=Vp6543GLwbW6KOkj',
                start: 283,
                end: 315,
                ytThumbnail: 'https://i.ytimg.com/vi_webp/BSmIi6AptEw/maxresdefault.webp',
                logo: <CampingInTheSkiesLogo />
            },
            {
                name: 'Camping In The Skies Lyrics Video',
                videoId: '0JkbqqlhSEg',
                start: 283,
                end: 315,
                ytThumbnail: 'https://i.ytimg.com/vi_webp/0JkbqqlhSEg/sddefault.webp',
                logo: <CampingInTheSkiesLogo />
            },
            {
                name: 'Moving On Live Acoustic Video',
                isNameOnThumbnail: false,
                videoId: 'rhrfDMPIAIc',
                playlistId: 'https://www.youtube.com/embed/rhrfDMPIAIc?si=xGnOLclE7EKApMV9',
                start: 167,
                end: 178,
                ytThumbnail: 'https://i.ytimg.com/vi/rhrfDMPIAIc/maxresdefault.jpg?sqp=-oaymwEmCIAKENAF8quKqQMa8AEB-AH-CYAC0AWKAgwIABABGH8gRygmMA8=&rs=AOn4CLBRfJ93r23u4zum_2y3_T-OhvrutQ',
                logo: <CampingInTheSkiesLogo />
            },
            {
                name: 'Camping In The Skies Live Multicam',
                videoId: 'enGr8VVamJM',
                start: 167,
                end: 178,
                ytThumbnail: 'https://i.ytimg.com/vi/enGr8VVamJM/sddefault.jpg',
                logo: <CampingInTheSkiesLogo />
            },
            { socialMedia: followOnYouTubeCits, icon: socialMediaIcons.youTube},
        ]
    ), []);
};

export const BackgroundPlayerData = () => {
    return useMemo(() => (
        [
            {
                name: 'Disobey Official Music Video',
                videoId: 'https://www.youtube.com/embed/FTlNNAjyDr0',
                start: 331,
                end: 345,
            },
            {
                name: 'Camping In The Skies Official Music Video',
                videoId: 'https://www.youtube.com/embed/v5QQHKf1L58',
                start: 331,
                end: 345,
            },
            {
                name: 'Moving On Official Music Video',
                videoId: 'https://www.youtube.com/embed/-L40ZD2JVxc',
                start: 134,
                end: 145,
            },
            {
                name: 'Camping In The Skies Live Acoustic Video',
                videoId: 'https://www.youtube.com/embed/BSmIi6AptEw',
                start: 283,
                end: 315,
            },
        ]
    ), []);
};
export const aboutUsCarouselImages = [
    {image: 'https://mattselvans.com/wp-content/uploads/2023/05/Map-recording-768x634.jpg', alt: 'Singing home'},
    {image: 'https://mattselvans.com/wp-content/uploads/2023/05/Map-Busking-2022-768x634.jpg', alt: 'Busking map'},
    {
        image: 'https://mattselvans.com/wp-content/uploads/2023/05/BuskingNewCastle-Text-768x634.png',
        alt: 'Busker since 2011'
    },
];
export const ListenFollowSharePlatforms = ({size}) => {
    const {dictionary} = useContext(LanguageContext);

    const shareOnWhatsAppSelvans = `${shareOnWhatsAppThe}Have a listen to this band %0A${homepage}`

    return useMemo(() => (
        [
            {
                name: dictionary.listen,
                icon: LibraryMusicIcon,
                platforms: [
                    {
                        name: 'Bandcamp',
                        link: citsBandcamp,
                        icon: <BandCampIcon size={size}/>
                    },
                    {
                        name: 'YouTube Music',
                        link: youTubeMusicCits,
                        icon: <YouTubeMusicIcon size={size}/>
                    },
                    {
                        name: 'Amazon Music',
                        link: amazonMusicCits,
                        icon: <AmazonMusicIcon size={size}/>
                    },
                    {
                        name: 'Apple Music',
                        link: appleMusicCits,
                        icon: <AppleMusicIcon size={size}/>
                    },
                    {
                        name: 'Spotify',
                        link: spotifyCits,
                        icon: <SpotifyIcon size={size}/>
                    },
                ]
            },
            {
                name: dictionary.follow,
                icon: AddReactionIcon,
                platforms: [
                    {
                        name: 'Facebook',
                        link: followOnFacebookCits,
                        icon: <FacebookIcon size={size}/>
                    },
                    {
                        name: 'Instagram',
                        link: followOnInstagramCits,
                        icon: <InstagramIcon size={size}/>
                    },
                    {
                        name: 'YouTube',
                        link: followOnYouTubeCits,
                        icon: <YouTubeIcon size={size}/>
                    },
                ]
            },
            {
                name: dictionary.share,
                icon: ShareIcon,
                platforms: [
                    {
                        name: 'Facebook',
                        link: shareFacebookCits,
                        icon: <FacebookIcon size={size}/>
                    },
                    {
                        name: 'WhatsApp',
                        link: shareOnWhatsAppSelvans,
                        icon: <WhatsAppIcon size={size}/>
                    },
                    {
                        name: dictionary.copyLink,
                        linkToCopy: citsWebsite,
                        icon: <ContentCopyIcon sx={{fontSize: size}}/>
                    },
                    {
                        name: 'Twitter',
                        link: shareTwitterCits,
                        icon: <TwitterIcon size={size}/>
                    },
                ]
            },
        ]
    ), [dictionary.copyLink, dictionary.follow, dictionary.listen, dictionary.share, shareOnWhatsAppSelvans, size]);
};