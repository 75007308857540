import { createTheme } from '@mui/material';
import { amber, deepOrange } from '@mui/material/colors';

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#002a4d',
        },
        divider: deepOrange[700],
        background: {
            default: '#ffffff',
            paper: '#ffffff',
        },
        text: {
            primary: '#000000',
            secondary: '#677d99',
        },
    },
    typography: {
        fontFamily: 'Copperplate, Copperplate Gothic Light, sans-serif',
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    // Hide scrollbar for Chrome, Safari and Opera
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    // Hide scrollbar for IE, Edge and Firefox
                    '-ms-overflow-style': 'none', // IE and Edge
                    scrollbarWidth: 'none', // Firefox
                },
            },
        },
    },
});

export const lightTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#002a4d',
        },
        divider: deepOrange[700],
        background: {
            default: '#ffffff',
            paper: '#ffffff',
        },
        text: {
            primary: '#000000',
            secondary: '#677d99',
        },
    },
    typography: {
        fontFamily: 'Copperplate, Copperplate Gothic Light, sans-serif',
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    // Hide scrollbar for Chrome, Safari and Opera
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    // Hide scrollbar for IE, Edge and Firefox
                    '-ms-overflow-style': 'none', // IE and Edge
                    scrollbarWidth: 'none', // Firefox
                },
            },
        },
    },
});