import React, { useContext, useState, useEffect } from 'react';
import Carousel from 'react-material-ui-carousel';
import Box from "@mui/material/Box";
import {Grid, Typography} from "@mui/material";
import { LayoutContext } from "../../contexts/LayoutContext/LayoutContext";
import {OgMetaTagsPhotoAlbums} from "./OgMetaTags";

// photoAlbumName, photoAlbumDescription, PhotoAlbum.photos

export const PhotoAlbum = ({ PhotoAlbum }) => {

    const { setIsNavBarVisible, isMobile, cardsPadding, isTablet } = useContext(LayoutContext);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);

    const handleImageClick = (index) => {
        setSelectedImageIndex(index);
        setIsNavBarVisible(false);
    };

    const handleCloseCarousel = () => {
        setSelectedImageIndex(null);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.code === 'ArrowRight' && selectedImageIndex !== null) {
                // Move forward in the carousel
                setSelectedImageIndex((prevIndex) => (prevIndex + 1) % PhotoAlbum.photos.length);
            } else if (event.code === 'ArrowLeft' && selectedImageIndex !== null) {
                // Move backward in the carousel
                setSelectedImageIndex((prevIndex) =>
                    (prevIndex - 1 + PhotoAlbum.photos.length) % PhotoAlbum.photos.length
                );
            } else if (event.code === 'Escape') {
                handleCloseCarousel();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedImageIndex, PhotoAlbum.photos]);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
                width: '100%',
                paddingBottom: 20,
                paddingTop: 17,
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${PhotoAlbum.photos[0]})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
            }}
        >
            <OgMetaTagsPhotoAlbums content={PhotoAlbum} />
            <Grid item large={11} xs={11} sx={{ textAlign: 'center' }}>
                <Typography variant="h3" sx={{color: '#fff'}}>{PhotoAlbum.albumName}</Typography>
                <Typography variant="body1" sx={{color: '#fff', whiteSpace: 'pre-line',}}>{PhotoAlbum.description}</Typography>
            </Grid>
            <Grid item
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
            >
                {PhotoAlbum.photos.map((image, i) => (
                    <Grid
                        item
                        key={i}
                        id={i}
                        sx={{ padding: cardsPadding, }}
                        onClick={() => handleImageClick(i)}
                    >
                        <Box
                            height={ isMobile ? "90vw" : 400}
                            width={ isMobile ? "90vw" : 400}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column', // Center content vertically
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${image})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                ':hover': {
                                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url(${image})`,
                                },
                            }}
                        >
                        </Box>
                    </Grid>
                ))}
            </Grid>
            {selectedImageIndex !== null && (
                <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%'}}>
                    <div style={{ position: 'relative', width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.8)' }}>
                        <Carousel
                            id={"carousel"}
                            indicators={false}
                            enableSwipe={true}
                            autoPlay={false}
                            navButtonsAlwaysVisible={true}
                            navButtonsProps={{
                                style: {
                                    backgroundColor: 'rgba(245,196,7,0.5)',
                                    margin: 'auto',
                                },
                            }}
                            navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
                                style: {
                                    position: 'fixed',
                                }
                            }}
                            index={selectedImageIndex}
                            onRequestChange={(newIndex) => setSelectedImageIndex(newIndex)}
                        >
                            {PhotoAlbum.photos.map((image, i) => (
                                <Box key={i} style={{ position: 'fixed', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', left: '50%', transform: 'translateX(-50%)' }}>
                                    <img
                                        src={image}
                                        alt={`${i}`}
                                        style={{
                                            objectFit: 'contain',
                                            maxHeight: '100vh',
                                            maxWidth: '100vw'
                                        }}
                                    />
                                </Box>
                            ))}
                        </Carousel>
                        <div
                            onClick={handleCloseCarousel}
                            style={{
                                marginTop: -15,
                                color: '#fff',
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                padding: '10px',
                                zIndex: 999,
                                fontSize: 50,
                            }}
                        >
                            X
                        </div>
                    </div>
                </div>
            )}
        </Grid>
    );
};
