import React, {useCallback, useContext, useEffect, useState} from 'react';
import { createUseStyles } from "react-jss";
import {LanguageContext} from "../contexts/LocalizationContext/languages/LanguageContext";
import {Typography} from "@mui/material";
import {ListenFollowSharePlatforms} from "../displayConfig";

const useStyles = createUseStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        margin: '0 auto',
    },
    categoriesBarContainer: {
        '&:not(:first-child)': {
            borderLeft: '2px solid white', // Add white border to all other categories
        },
    },
    categories: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'black',
        textAlign: 'center',
        float: 'left',
        outline: 'none',
        cursor: 'pointer',
        paddingBottom: '5px',
        paddingTop: '5px',
        transition: '0.3s',
        fontSize: '15px',
        color: '#f1f1f1',
        width: '100%',
    },
    categoryIcon: {
      height: '25px',
      marginRight: '5px',
    },
    floatingWindow: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        paddingTop: '10px',
        paddingBottom: '10px',
        width: '400px',
        backgroundColor: 'rgba(42, 42, 42, 1)',
        color: '#e0e0e0',
        borderRadius: '5px',
        zIndex: '998',
    },
    closeFloatingWindow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '20px',
        fontWeight: 'bolder',
        color: '#e0e0e0',
        fontSize: '25px',
    },
    platformsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        maxWidth: '400px',
        margin: '0 auto',
    },
    lastPlatformsContainer: {
        justifyContent: 'flex-start',
    },
    buttons: {
        // display: 'none',
        width: '140px',
        height: 97,
        textDecoration: 'none',
        color: '#ffffff',
        marginBottom: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
    },
    buttonsIcon: {
        width: '50px',
    },
}));


export const ListenFollowShareBar = ({containerWidth}) => {

    const mediaIconsSize = 65

    const classes = useStyles();
    const { dictionary } = useContext(LanguageContext);
    const [showPlatformsFloatingWindow, setShowPlatformsFloatingWindow] = useState('');
    const [showCopiedFloatingWindow, setShowCopiedFloatingWindow] = useState(false);
    const listenFollowSharePlatforms = ListenFollowSharePlatforms({ size: mediaIconsSize });

    const closePlatformsFloatingWindows = useCallback(() => {
        setShowPlatformsFloatingWindow('');
    }, [setShowPlatformsFloatingWindow]);

    // esc closes floating window
    useEffect(() => {
        const handleEscKey = (event) => {
            if (event.key === 'Escape') {
                setShowPlatformsFloatingWindow('');
            }
        };

        window.addEventListener('keydown', handleEscKey);

        return () => {
            window.removeEventListener('keydown', handleEscKey);
        };
    }, []);

    // click outside container closes floating window
    useEffect(() => {
        const handleClickOutside = (event) => {
            const container = document.querySelector(`.${classes.container}`);

            if (container && !container.contains(event.target)) {
                closePlatformsFloatingWindows();
            }
        };

        window.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [classes.container, closePlatformsFloatingWindows]);

    // copied link floating window notification
    useEffect(() => {
        let timer;

        if (showCopiedFloatingWindow) {
            closePlatformsFloatingWindows()
            timer = setTimeout(() => {
                setShowCopiedFloatingWindow(false);
            }, 1000);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [closePlatformsFloatingWindows, showCopiedFloatingWindow]);

    const copyToClipBoard = () => {
        navigator.clipboard
            .writeText('mattselvans.com')
            .then(() => {
                console.log('URL copied to clipboard');
                setShowCopiedFloatingWindow(true);
            })
            .catch((error) => {
                console.error('Failed to copy URL to clipboard:', error);
            });
    };


    const categoriesNumber = listenFollowSharePlatforms.length
    const widthPercentage = 100 / categoriesNumber

    return (
        <div className={classes.container} style={{width: containerWidth}}>
            {listenFollowSharePlatforms.map((category, i) => (
                <div className={classes.categoriesBarContainer} style={{width: `${widthPercentage}%`}}>
                    <div id={i} className={classes.categories} onClick={() => setShowPlatformsFloatingWindow(category.name)}>
                        <category.icon className={classes.categoryIcon}/>
                        <Typography>{category.name}</Typography>
                    </div>
                    <br/>
                    { showPlatformsFloatingWindow === category.name && (
                        <div className={classes.floatingWindow}>
                            <div className={classes.closeFloatingWindow}>
                                <Typography style={{marginLeft: '20px'}}>{category.name}</Typography>
                                <Typography
                                    style={{marginRight: '20px', paddingLeft: '20px', color: 'white', cursor: 'pointer'}}
                                    onClick={() => closePlatformsFloatingWindows()}
                                >
                                    X
                                </Typography>
                            </div>
                            <div className={classes.platformsContainer}>
                                {category.platforms.map((platform, j) => (
                                    platform.linkToCopy !== undefined ? (
                                        <div
                                            key={j}
                                            className={classes.buttons}
                                            onClick={copyToClipBoard}
                                        >
                                            {platform.icon}
                                            <Typography>{platform.name}</Typography>
                                        </div>
                                    ) : (
                                        <a key={j} className={classes.buttons} href={platform.link} target="_blank" rel="noreferrer" onClick={() => closePlatformsFloatingWindows()}>
                                            {/* Render the platform.icon directly */}
                                            {platform.icon}
                                            <Typography>{platform.name}</Typography>
                                        </a>
                                    )
                                ))}
                            </div>

                        </div>
                    )}
                </div>
            ))}
            {showCopiedFloatingWindow && (
                <div className={classes.floatingWindow} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50px' }}>
                    <Typography align="center" >{dictionary.linkCopied}</Typography>
                </div>
            )}
        </div>
    );
};
