import { Helmet } from "react-helmet";

const mattSelvans = 'Matt Selvans'

export const OgMetaTagsPhotoAlbums = ({ content }) => {
    function removeTextFormatting(text) {
        // Remove the first newline character and replace all other consecutive newlines with a ". "
        const transformedText = text.replace('\n', ' ').replace(/\n+/g, '. ');

        // Remove all HTML tags (e.g., <b> and </b>) and replace multiple spaces with a single space
        const cleanedText = transformedText.replace(/<[^>]*>/g, '').replace(/\s+/g, ' ');

        // Remove quotes unless preceded by a backslash
        return cleanedText.replace(/\\?"/g, match => (match === '\"' ? '' : match));
    }

    const transformedText = removeTextFormatting(content.description);

    return (
        <Helmet>
            <title>{content.albumName}</title>
            <meta name='description' content={transformedText} />
            <meta name='keywords' content={`${content.keywords}, ${mattSelvans}`} />
            <meta property="og:image" content={content.albumCover} />
        </Helmet>
    );
};

export const OgMetaTagsPages = ({ content }) => {

    return (
        <Helmet>
            <title>{content.pageName}</title>
            <meta name='description' content={content.description} />
            <meta name='keywords' content={`${content.keywords}, ${mattSelvans}`} />
            <meta property="og:image" content={content.ogImage} />
        </Helmet>
    );
};
