import React from 'react';
import {Typography} from '@mui/material';
import {fullMembersDataArray} from "../displayConfig";
import PartnersCarousel from "./PartnersCarousel/PartnersCarousel";
import Box from "@mui/material/Box";
import {LayoutContext} from "../contexts/LayoutContext/LayoutContext";
import {useContext} from "react";
import Grid from '@mui/material/Grid';
import {OgMetaTagsPages} from "./ReusableComponents/OgMetaTags";
import {PagesList} from "../config";
import {Link} from "react-router-dom";
import {SocialMediaIconsRow} from "./Homepage";

export default function HomePageAboutUsCarousel({ pageMeta }) {
    const { isMobile } = useContext(LayoutContext)
    return (
        <Box>
            <OgMetaTagsPages content={pageMeta} />
            <Box sx={{

                backgroundImage:`linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(https://cdn.eso.org/images/screen/eso1145a.jpg)`,
                backgroundSize: isMobile ? 'cover' : 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#fff',
                paddingTop: isMobile ? 10 : 20,
                paddingBottom: isMobile ? 10 : 20,
            }}>
                <Grid container>
                    <Grid item margin="auto" bgcolor={'rgba(0,0,0,0.8)'} lg={10} sm={12} xs={12} padding="15px 10px">
                        <Typography textAlign="center" variant="h3">
                            What is Camping In The Skies?
                        </Typography>
                        <br/>
                        <Typography variant="body1" sx={{fontSize: isMobile ? 15 : 30, whiteSpace: 'pre-line',}}>
                            {" Camping In The Skies is a rock band where land is no limit, taking you up and over the clouds with soaring melodies and progressive song structures.\n" +
                                "\n" +
                                " The band is a creative partnership between vocalist Matt Sevlans and guitarist Rodaidh Mackay.\n" +
                                "\n" +
                                " Matt and Rodaidh originally met in Edinburgh in 2016 and clicked creatively, working on their first song together, titled “Muse”, which will be the band’s next single launching December 1st 2023. Muse is the first song that Matt and Rodaidh wrote together many years ago, and marks a milestone in their musical journey.\n" +
                                "\n" +
                                " Since then, they have continued working together, building a collection of music and developing a collective style. This eventually led to officially launching a project together as Camping in the Skies in 2022 to begin releasing their music.\n" +
                                "\n" +
                                " The band is based in Edinburgh, but works remotely between Tenerife and Scotland, collaborating with various talented musicians from different countries to complete the full band line up. The band has released three singles so far, each showcasing a different facet of their musical style:\n" +
                                "\n" +
                                "•  Camping In The Skies: A groovy progressive song about finding your calling in the world, along with a conceptual music video about a shipwrecked individual on a dark enchanted island. The filming of the video involved camping for a week in a very cold Scottish December, which both tested and bonded the group, and inspired the name of the band as well as the song.\n" +
                                "\n" +
                                "•  Moving On: A classic bare roots metal song which pays homage to influences of early Sabbath, explored with a contrasting psychedelic intersection more akin to the style of Pink Floyd.\n" +
                                "\n" +
                                "•  Disobey: An empowering rock anthem about embracing your true nature, with a music video about breaking the mold of every day, featuring iconic locational shots around Edinburgh.\n" +
                                "\n" +
                                " Over the year, the band has also performed live and recorded several performance videos that will be released soon. Be sure to check out the media section to explore their videos to date.\n" +
                                "\n" +
                                " The band's lyrics and themes are diverse but often circle around self-belief and self-empowerment, as well as exploring some of the complex and sometimes darker human experiences. The band has a keen interest in the visual element of the music, creating music videos and live performance videos to complement their music.\n" +
                                "\n" +
                                " The band is currently working on new music and towards future live performances whilst releasing their backlog of music they have created together so far. "
                                }
                        </Typography>
                        <br/>
                        <Typography textAlign="center" variant="body1" sx={{fontSize: isMobile ? 15 : 30, whiteSpace: 'pre-line',}}>
                            <Link to={PagesList.discography.route} style={{ color: '#1b3fff', textAlign: 'center'}}>Click here </Link>
                            {" to explore their discography \n Stay tuned by subscribing to the "}
                            <Link to={PagesList.newsletter.route} style={{  color: '#1b3fff', textAlign: 'center'}}>newsletter.</Link>
                            <br/>
                            {"\nFollow Camping In The Skies on social media"}
                        </Typography>
                        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" paddingTop={1}>
                            <SocialMediaIconsRow />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {/*<br/><br/>*/}
            {/*<Typography textAlign="center" variant="h4">*/}
            {/*    Members*/}
            {/*</Typography>*/}
            {/*<br/>*/}
            {/*<PartnersCarousel members={fullMembersDataArray}/>*/}
        </Box>
    );
}

